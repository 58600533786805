import styled from "styled-components"

export const AppContainer = styled.div`
  color: ${({ theme }) => theme.color};
  background-color: ${({ theme }) => theme.backgroundColor.primary};
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
`

export const LeftContainer = styled.div`
  width: 170px;
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  border-right: 1px solid ${({ theme }) => theme.borderColor};
`
export const RightContainer = styled.div`
  width: calc(100vw - 135px);
  height: 100vh;
  overflow-y: scroll;
`

export const AppContent = styled.div`
  color: ${({ theme }) => theme.color};
  margin: 65px 0 0 20px;
  display: flex;
`

export const SideNavWhiteSpace = styled.div`
  min-width: 135px;
  height: 100vh;
`

export const BottomWhiteSpace = styled.div`
  height: 80px;
`
