import React, { useEffect, useState, useRef } from "react"
import { DefaultTheme, useTheme } from "styled-components"
import {
  Container,
  Title,
  StandardContainer,
  Level,
  LevelContainer,
} from "./styles.css"
import { useHistory } from "react-router-dom"
import {
  CollatedStandardObservation,
  StandardObservation,
} from "../../../../../../../global"
import { H4, P } from "../../../../../../components/base/styles.css"
import { GateLevel, StandardLevel } from "../../../../../../enums"
import styled from "styled-components"
import { colorGradient } from "../../../../../../utils"
import {
  Chart,
  ChartItem,
  ChartConfiguration,
  BarController,
  BarElement,
  TimeScale,
  CategoryScale,
  PointElement,
  TimeSeriesScale,
  Tooltip,
  LinearScale,
  CoreScaleOptions,
} from "chart.js"

import { format } from "date-fns"

Chart.register(
  BarElement,
  PointElement,
  CategoryScale,
  LinearScale,
  BarController,
  TimeScale,
  TimeSeriesScale,
  Tooltip
)
const tooltipPlugin = Chart.registry.getPlugin("tooltip")

const ToolP = styled(H4)`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  & span {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    font-size: ${({ theme }) => theme.font.size.s};
  }
  &:hover span {
    visibility: visible;
  }
`

const Standard = ({
  standard,
}: {
  standard: CollatedStandardObservation
  compare?: boolean
}) => {
  const history = useHistory()
  const standardSubmissionChartRef = useRef<HTMLCanvasElement>(null)
  const [standardSubmissionChart, setstandardSubmissionChart] =
    useState<Chart<"bar", number[], string>>()

  const gating = (standard.observations || []).filter(
    ({ gate_level }) => gate_level === GateLevel.GATING
  )
  const gatingEventual = (standard.observations || []).filter(
    ({ gate_level }) => gate_level === GateLevel.GATING_EVENTUAL
  )
  const nonGating = (standard.observations || []).filter(
    ({ gate_level }) => gate_level === GateLevel.NON_GATING
  )

  const colors = {
    [StandardLevel.DEMONSTRATED_UNDERSTANDING]: colorGradient(1),
    [StandardLevel.IMPLIED_UNDERSTANDING]: colorGradient(0.8),
    [StandardLevel.IMPLIED_MISUNDERSTANDING]: colorGradient(0.3),
    [StandardLevel.DEMONSTRATED_MISUNDERSTANDING]: colorGradient(0),
  }

  // const genBackground = (theme: "light" | "dark") => {}

  const theme = useTheme() as DefaultTheme & { status: string }

  const styles =
    theme.status === "light"
      ? {
          color: "white",
          text: "#333333",
          hrBlue: "skyblue",
          borderColor: "lightgrey",
        }
      : {
          color: "#333333",
          text: "white",
          hrBlue: "skyblue",
          borderColor: "grey",
        }

  const gatingTypes = {
    4: ["Demonstrated", "Understanding"],
    3: ["Implied", "Understanding"],
    2: ["Implied", "Misunderstanding"],
    1: ["Demonstrated", "Misunderstanding"],
  }
  const gatingPoints = {
    [StandardLevel.DEMONSTRATED_UNDERSTANDING]: 4,
    [StandardLevel.IMPLIED_UNDERSTANDING]: 3,
    [StandardLevel.IMPLIED_MISUNDERSTANDING]: 2,
    [StandardLevel.DEMONSTRATED_MISUNDERSTANDING]: 1,
  }
  useEffect(() => {
    if (standardSubmissionChartRef.current) {
      if (standardSubmissionChart) standardSubmissionChart.destroy()
      const barGradients = standard.observations.map(({ observation }) => {
        switch (observation) {
          case StandardLevel.DEMONSTRATED_UNDERSTANDING:
            return colors[StandardLevel.DEMONSTRATED_UNDERSTANDING]
          case StandardLevel.IMPLIED_UNDERSTANDING:
            return colors[StandardLevel.IMPLIED_UNDERSTANDING]
          case StandardLevel.IMPLIED_MISUNDERSTANDING:
            return colors[StandardLevel.IMPLIED_MISUNDERSTANDING]
          case StandardLevel.DEMONSTRATED_MISUNDERSTANDING:
            return colors[StandardLevel.IMPLIED_MISUNDERSTANDING]
          default:
            return theme.status === "light" ? styles.hrBlue : styles.color
        }
      })

      setstandardSubmissionChart(
        new Chart(
          standardSubmissionChartRef.current.getContext(
            "2d"
          ) as unknown as ChartItem,
          {
            type: "bar",
            data: {
              labels: standard.observations.map(({ observed_at }) =>
                observed_at
                  ? format(new Date(observed_at), "MM/dd/yyyy")
                  : "undefined"
              ),
              datasets: [
                {
                  data: standard.observations.map(({ observation: o }) =>
                    o ? (gatingPoints[o as StandardLevel] as number) : 0
                  ),
                  borderColor: barGradients,
                  backgroundColor: barGradients,
                  borderWidth: 1,
                  barThickness: 30,
                },
              ],
            },
            options: {
              maintainAspectRatio: true,
              hover: {
                mode: "x",
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function (context: any) {
                      const { observed_by, observation, module_name } =
                        standard.observations.find(
                          ({ observed_at }) =>
                            format(
                              new Date(observed_at as string),
                              "MM/dd/yyyy"
                            ) === context.label
                        ) || {
                          observation: "",
                          observed_by: "",
                          module_name: "",
                        }
                      const author = (observed_by as string)
                        .split(".")
                        .slice(0, 2)
                        .map((word, idx) =>
                          idx === 1
                            ? word.split("@")[0][0].toUpperCase() +
                              word.split("@")[0].slice(1)
                            : word[0].toUpperCase() + word.slice(1)
                        )
                        .join(" ")
                      return [
                        observation,
                        `Observer: ${author}`,
                        `Module: ${module_name}`,
                      ]
                    },
                  },
                },
              },
              elements: {
                point: {
                  radius: 0,
                  hitRadius: 4,
                  hoverRadius: 6,
                },
              },
              scales: {
                y: {
                  suggestedMin: 0,
                  suggestedMax: 4,
                  grid: {
                    borderColor: styles.borderColor,
                    tickColor: styles.borderColor,
                    color: styles.borderColor,
                    borderDash: [5, 20],
                    beginAtZero: true,
                  },
                  ticks: {
                    padding: 0,
                    afterBuildTicks: function (scale: any) {
                      scale.ticks = [4, 3, 2, 1, 0]
                      return
                    },
                    beforeUpdate: function (oScale: any) {
                      return
                    },
                    stepSize: 1,
                    autoSkip: false,
                    color: styles.text,
                    callback: function (
                      value: keyof {
                        4: StandardLevel[]
                        3: StandardLevel[]
                        2: StandardLevel[]
                        1: StandardLevel[]
                      }
                    ) {
                      // console.log(gatingTypes[value])
                      return gatingTypes[value]
                    },
                  },
                  beginAtZero: false,
                },
                x: {
                  grid: {
                    borderColor: styles.borderColor,
                    tickColor: styles.borderColor,
                    color: styles.borderColor,
                    borderDash: [5, 20],
                  },
                  ticks: {
                    color: styles.text,
                  },
                  scales: {
                    barPercentage: 0.4,
                  },
                },
              },
            },
          } as ChartConfiguration<any, number[], string>
        )
      )
    }
  }, [standardSubmissionChartRef, theme])

  return (
    <Container>
      {standard.observations.length > 0 &&
      standard.observations.some(({ observation: o }) => o.length > 0) ? (
        <StandardContainer>
          <canvas id="app-submissions-chart" ref={standardSubmissionChartRef} />
        </StandardContainer>
      ) : null}
    </Container>
  )
}

export default Standard
