import React from "react"
import ReactDOM from "react-dom"
import Login from "./features/login"
import App from "./components/app"
import { GlobalStyle } from "./styles.css"

import { Provider } from "react-redux"
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import rootReducer from "./reducers"

import { STAGE } from "./constants"

import { FixMe } from "../global"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

const logger = (store: FixMe) => (next: FixMe) => (action: FixMe) => {
  console.log(`📻 ${action.type}: `, action)
  const result = next(action)
  console.log("➡ Next State", store.getState())
  return result
}

const store = configureStore({
  reducer: rootReducer,
  middleware:
    String(STAGE) === "prod"
      ? [...getDefaultMiddleware()]
      : [...getDefaultMiddleware(), logger],
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyle />
      <Router>
        <Route path="/login" component={Login} />
        <Route path="/" component={App} />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)
