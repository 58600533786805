import styled from "styled-components"

export const Container = styled.table`
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > div {
    width: 600px;
  }
`
