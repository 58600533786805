import React from "react"
import { Container, Title, TitleContainer, PageActions } from "./styles.css"
import BackButton from "../base/backButton"
import { useHistory } from "react-router-dom"
import { Button, LabelDescription } from "../base/styles.css"

interface PageAction {
  name: string
  component?: React.FunctionComponentElement<HTMLElement>
  handler: () => void
}

interface Props {
  title: string
  pageActions: PageAction[]
  subtitle?: string | null
}

const PageHeader = (props: Props) => {
  const { title, pageActions } = props
  const history = useHistory()

  if (!title) return null

  const style = {
    display: "flex",
    alignItems: "center",
  }

  return (
    <Container>
      <TitleContainer style={props.subtitle ? {} : style}>
        <BackButton handleBackArrowClick={() => history.goBack()} />
        <div>
          <Title>{title}</Title>
          {props.subtitle && (
            <LabelDescription>{props.subtitle}</LabelDescription>
          )}
        </div>
      </TitleContainer>
      <PageActions>
        {pageActions.map(
          ({ handler, name, component }: PageAction, idx: number) =>
            component ? (
              <div style={{ marginRight: "10px" }} key={`action-button-${idx}`}>
                {component}
              </div>
            ) : (
              <Button key={`action-button-${idx}`} onClick={handler}>
                {name}
              </Button>
            )
        )}
      </PageActions>
    </Container>
  )
}

export default PageHeader
