import styled from "styled-components"
import { H0 } from "../base/styles.css"

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 46px;
  padding-top: 3px;
  background: ${({ theme }) => theme.backgroundColor.navbar};
  height: 55px;
  z-index: 5;
`

export const Title = styled(H0)`
  font-size: ${({ theme }) => theme.font.size.xl};
  margin: 0;
`

export const TitleContainer = styled.div`
  display: flex;
  max-width: 800px;
`

export const PageActions = styled.div`
  display: flex;
  button {
    margin-right: 20px;
  }
`
