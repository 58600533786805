import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  Cohort,
  FixMe,
  GoogleID,
  ReduxStatus,
  Student,
} from "../../../../../global"
import { student } from "../../../../services/api"

interface InitialStudentsState {
  status: ReduxStatus
  msg: string | null
  data: Student[]
}

const initialState: InitialStudentsState = {
  status: "pending",
  msg: null,
  data: [],
}

export const fetchStudents = createAsyncThunk(
  "students/fetch",
  async ({
    cohort_name,
    campus_data_id,
  }: {
    cohort_name: string
    campus_data_id: GoogleID
  }) => {
    return await student
      .fetch(cohort_name, campus_data_id)
      .then((students) =>
        students.sort(({ name: nameOne }, { name: nameTwo }) =>
          nameOne.localeCompare(nameTwo)
        )
      )
      .catch(({ message }) =>
        Promise.reject("Could not retrieve Students. " + message)
      )
  }
)
export const updateStudentForms = createAsyncThunk(
  "students/updateForms",
  async ({
    cohorts,
    cohort_name,
    students,
  }: {
    cohorts: Cohort[]
    cohort_name: string
    students: Student[]
  }) => {
    return await student
      .sortSubmissions(cohorts, cohort_name, students)
      .catch(({ message }) =>
        Promise.reject("Could not update student forms. " + message)
      )
  }
)

const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    setStudents(state, action) {
      state.status = "ready"
      state.data = action.payload
      state.msg = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStudents.fulfilled, (state, action: FixMe) => {
      state.status = "ready"
      state.data = action.payload
      state.msg = null
    })
    builder.addCase(fetchStudents.pending, (state) => {
      state.status = "pending"
    })
    builder.addCase(fetchStudents.rejected, (state, action) => {
      state.status = "error"
      state.msg = action.error.message + ""
    })
    builder.addCase(updateStudentForms.fulfilled, (state, action: FixMe) => {
      state.status = "ready"
      state.data = action.payload
      state.msg = null
    })
    builder.addCase(updateStudentForms.pending, (state) => {
      state.status = "pending"
    })
    builder.addCase(updateStudentForms.rejected, (state, action) => {
      state.status = "error"
      state.msg = action.error.message + ""
    })
  },
})

export const { setStudents } = studentsSlice.actions
export default studentsSlice.reducer
