import React, { useEffect, useState } from "react"

import { connect, ConnectedProps } from "react-redux"
import { fetchStudents } from "../studentSlice"
import { useSnackBar } from "../../../../../hooks/useSnackbar"

import {
  Container,
  ModalContainer,
  ModuleContainer,
  ModuleName,
  StandardContainer,
  Title,
  Chip,
} from "./styles.css"

import {
  ReduxMsg,
  ReduxStatus,
  Student as IStudent,
  User,
  FormHistory,
  Cohort as ICohort,
  LearningStandard,
  StandardObservation,
  CollatedStandardObservation,
} from "../../../../../../global"
import { useHistory, useLocation, useRouteMatch } from "react-router"
import PageHeader from "../../../../../components/pageHeader"
import { FormTemplate } from "../../../../../enums"
import StaffNote from "../../../../../components/formResponse/staffNote"
import PairingObservation from "../../../../../components/formResponse/pairingObservation"
import { Modal } from "@material-ui/core"
import {
  H0,
  H3,
  H4,
  LI,
  P,
  UL,
} from "../../../../../components/base/styles.css"
import Standard from "./standard"
import SprintWrapUp from "../../../../../components/formResponse/sprintWrapUp"
import HelpDesk from "../../../../../components/formResponse/helpDesk"
import WhiteboardReflection from "../../../../../components/formResponse/whiteboardReflection"
import { formatDistanceToNow } from "date-fns"

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux

const mapState =
  () =>
  ({
    cohorts,
    students,
    user_profile: userProfile,
    curriculum,
  }: {
    students: { status: ReduxStatus; msg: ReduxMsg; data: IStudent[] }
    cohorts: { status: ReduxStatus; msg: ReduxMsg; data: ICohort[] }
    user_profile: { status: ReduxStatus; msg: ReduxMsg; data: User }
    curriculum: {
      status: ReduxStatus
      msg: ReduxMsg
      data: LearningStandard[][]
    }
  }) => ({
    userProfile,
    students,
    cohorts,
    curriculum,
  })

const mapDispatch = { fetchStudents }
const connector = connect(mapState, mapDispatch)

const Student = ({
  students: { status: students_status, data: students },
  cohorts: { status: cohorts_status, data: cohorts },
  userProfile: { status: userProfile_status, data: userProfile },
  curriculum: { status: curriculum_status, data: curriculum },
  fetchStudents,
}: Props) => {
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()
  const [open, setOpen] = useState(false)
  const [showSprintWrapUpSummary, setShowSprintWrapUpSummary] = useState(false)

  const cohortName = location.pathname.split("/")[2].toUpperCase()
  const studentName = location.pathname
    .split("/")[3]
    .split("-")
    .map((name) => name[0].toUpperCase() + name.slice(1))
    .join(" ")
  const isReady = [
    students_status,
    cohorts_status,
    curriculum_status,
    userProfile_status,
  ].every((status) => status === "ready")

  if (!isReady) history.push(`/cohorts/${location.pathname.split("/")[2]}`)

  const student = students.find(
    ({ name }) => name.toLowerCase() === studentName.toLowerCase()
  ) as IStudent

  const renderSubmission = (form: FormHistory, studentName: string) => {
    const convertName = (name: string) =>
      name
        .toLowerCase()
        .split("_")
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join(" ")

    switch (form.form_name) {
      case convertName(FormTemplate.STAFF_NOTES): {
        return <StaffNote form={form} curriculum={curriculum} />
      }
      case convertName(FormTemplate.PAIRING_OBSERVATION): {
        return (
          <PairingObservation
            form={form}
            curriculum={curriculum}
            studentName={studentName}
          />
        )
      }
      case convertName(FormTemplate.HELP_DESK): {
        return (
          <HelpDesk
            form={form}
            curriculum={curriculum}
            studentName={studentName}
          />
        )
      }
      case convertName(FormTemplate.SPRINT_WRAP_UP): {
        return <SprintWrapUp form={form} studentName={studentName} />
      }
      case convertName(`${FormTemplate.WHITEBOARD}ING_REFLECTION`): {
        return <WhiteboardReflection form={form} studentName={studentName} />
      }
      default:
        return <div>Unable to render form submission</div>
    }
  }
  const studentForms = [] as (FormHistory & { submitted_at: string })[]
  student?.form_submissions?.forEach((form) => {
    const timestamp = form.headers?.indexOf("Timestamp") as number
    const additionalStudent = form.headers?.indexOf(
      "Additional Student (if present)"
    ) as number
    form.rows?.forEach((row) => {
      const isAdditionalStudent = row[additionalStudent] === student.name
      if (!isAdditionalStudent)
        studentForms.push({
          ...form,
          rows: [row],
          submitted_at: row[timestamp],
        })
    })
  })

  const sprintWrapUpForms = studentForms.filter(
    ({ form_name }) => form_name === "Sprint Wrap Up"
  )

  const sprintWrapUpFormQuestions = {} as any

  const authorNameIdx = (
    sprintWrapUpForms[0] || { headers: [] }
  ).headers?.indexOf("Your Name") as number
  const pairNameIdx = (
    sprintWrapUpForms[0] || { headers: [] }
  ).headers?.indexOf("Your Pair's Name") as number
  const timestampIdx = (
    sprintWrapUpForms[0] || { headers: [] }
  ).headers?.indexOf("Timestamp") as number

  const experienceIdx = (
    sprintWrapUpForms[0] || { headers: [] }
  ).headers?.indexOf(
    "How would you rate this pair programming experience?"
  ) as number

  ;(sprintWrapUpForms[0] || { headers: [] }).headers?.forEach(
    (header, headerIdx) => {
      sprintWrapUpFormQuestions[header] = []
      sprintWrapUpForms.forEach(({ rows }) => {
        const [response] = rows || [[]]
        sprintWrapUpFormQuestions[header].push({
          value: response[headerIdx],
          timestamp: response[timestampIdx],
          pair_name: response[pairNameIdx],
          author_name: response[authorNameIdx],
          experience: response[experienceIdx],
        })
      })
    }
  )

  const standardsSummary: StandardObservation[] = []
  // for each standard
  curriculum.forEach((module) => {
    module.forEach((learningStandard) => {
      let hasObservation = false
      // loop form history
      studentForms.forEach((form) => {
        // locate module name for header
        const { module_name, rows, headers } = form
        // locate observed_by and observed_at
        const timestamp = headers?.indexOf("Timestamp") as number
        const author = headers?.indexOf("Email Address") as number
        // validate student gets marks
        const studentOne = headers?.indexOf("Student One") as number
        const studentTwo = headers?.indexOf("Student Two") as number
        const primaryStudent = headers?.indexOf(
          "Primary Student's Name"
        ) as number

        const additionalStudent = headers?.indexOf(
          "Additional Student (if present)"
        ) as number
        // loop form responses
        rows?.forEach((row) => {
          // skip if student is only additional student
          if (row[additionalStudent] === student.name) return
          // loop form response values
          row.forEach((response, idx) => {
            const isMatchingStandard = headers
              ? learningStandard.standard === headers[idx]
              : false

            if (!isMatchingStandard) return
            // ensure right student if pairing observation
            const standardExists = headers
              ? headers.indexOf(learningStandard.standard) > -1
              : false
            const isPrimaryStudent = row[primaryStudent] === student.name
            const isStudentOne = row[studentOne] === student.name
            const isStudentTwo = row[studentTwo] === student.name
            const isFirstOccurance = headers
              ? headers.indexOf(learningStandard.standard) === idx
              : false
            const isSecondOccurance = headers
              ? headers.indexOf(
                  learningStandard.standard,
                  headers.indexOf(learningStandard.standard) + 1
                ) === idx
              : false
            if (headers && standardExists && isStudentOne && isFirstOccurance) {
              // if response value matches standard && module name matches form
              // push object to collection as StandardObservation
              hasObservation = true
              standardsSummary.push({
                ...learningStandard,
                module_name,
                form_name: form.form_name,
                form_number: form.form_number,
                observation: response,
                observed_at: row[timestamp],
                observed_by: row[author],
              } as StandardObservation)
            } else if (
              headers &&
              standardExists &&
              isStudentTwo &&
              isSecondOccurance &&
              isMatchingStandard
            ) {
              hasObservation = true
              standardsSummary.push({
                ...learningStandard,
                module_name,
                form_name: form.form_name,
                form_number: form.form_number,
                observation: response,
                observed_at: row[timestamp],
                observed_by: row[author],
              } as StandardObservation)
            } else if (headers && standardExists && isPrimaryStudent) {
              hasObservation = true
              standardsSummary.push({
                ...learningStandard,
                module_name,
                form_name: form.form_name,
                form_number: form.form_number,
                observation: response,
                observed_at: row[timestamp],
                observed_by: row[author],
              } as StandardObservation)
            }
          })
        })
      })

      if (!hasObservation) {
        standardsSummary.push({ ...learningStandard, observation: "" })
      }
    })
  })

  const collatedStandards = standardsSummary.reduce((acc, el, idx) => {
    const existingStandard = acc.findIndex(
      ({ standard }) => standard === el.standard
    )
    if (-1 < existingStandard && existingStandard < idx) {
      acc[existingStandard].observations.push(el)
    } else {
      acc.push({
        id: el.id,
        module_name: el.module_name,
        gate_level: el.gate_level,
        standard: el.standard,
        observations: [{ ...el }],
      })
    }
    return acc
  }, [] as Array<CollatedStandardObservation>)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Container>
      <PageHeader
        title={studentName}
        pageActions={[
          {
            handler: () => {
              setShowSprintWrapUpSummary(true)
              handleOpen()
            },
            name: "Sprint Wrap Up Summary",
          },
          {
            handler: () => {
              setShowSprintWrapUpSummary(false)
              handleOpen()
            },
            name: "Standards Summary",
          },
        ]}
      />

      <div>
        {studentForms
          .sort(
            (a: { submitted_at: string }, b: { submitted_at: string }) =>
              new Date(b.submitted_at).getTime() -
              new Date(a.submitted_at).getTime()
          )
          .map((form, idx) => (
            <div key={`submission-${idx}`}>
              {(form.rows || []).map((row, idy) => {
                return (
                  <div key={`row-${idy}`}>
                    {renderSubmission({ ...form, rows: [row] }, studentName)}
                  </div>
                )
              })}
            </div>
          ))}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {showSprintWrapUpSummary ? (
          <ModalContainer>
            <Title>
              <H0>Sprint Wrap Up Summary for {studentName}</H0>
            </Title>
            <ModuleContainer>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginRight: "20px",
                  }}
                >
                  <H3>Count Red Pairs</H3>
                  <P style={{ textAlign: "center" }}>
                    {
                      (
                        sprintWrapUpFormQuestions[
                          "What's the number one thing for your pair to work on?"
                        ] || []
                      ).filter(
                        ({
                          author_name,
                          experience,
                        }: {
                          author_name: string
                          experience: string
                        }) => author_name !== studentName && +experience < 4
                      ).length
                    }
                  </P>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginRight: "20px",
                  }}
                >
                  <H3>Count Yellow Pairs</H3>
                  <P style={{ textAlign: "center" }}>
                    {
                      (
                        sprintWrapUpFormQuestions[
                          "What's the number one thing for your pair to work on?"
                        ] || []
                      ).filter(
                        ({
                          author_name,
                          experience,
                        }: {
                          author_name: string
                          experience: string
                        }) =>
                          author_name !== studentName &&
                          +experience > 3 &&
                          +experience < 8
                      ).length
                    }
                  </P>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginRight: "20px",
                  }}
                >
                  <H3>Count Green Pairs</H3>
                  <P style={{ textAlign: "center" }}>
                    {
                      (
                        sprintWrapUpFormQuestions[
                          "What's the number one thing for your pair to work on?"
                        ] || []
                      ).filter(
                        ({
                          author_name,
                          experience,
                        }: {
                          author_name: string
                          experience: string
                        }) => author_name !== studentName && +experience > 7
                      ).length
                    }
                  </P>
                </div>
              </div>
              <H3>Personal Growth</H3>
              {(
                sprintWrapUpFormQuestions[
                  "What's the number one thing you can work on?"
                ] || []
              )
                .filter(
                  ({ author_name }: { author_name: string }) =>
                    author_name === studentName
                )
                .map((response: { value: string; timestamp: string }) => (
                  <P key={response.value}>
                    <Chip>
                      {formatDistanceToNow(new Date(response.timestamp), {
                        addSuffix: true,
                      })}
                    </Chip>
                    {response.value}
                  </P>
                ))}
              <H3>Pair Growth</H3>
              {(
                sprintWrapUpFormQuestions[
                  "What's the number one thing for your pair to work on?"
                ] || []
              )
                .filter(
                  ({ author_name }: { author_name: string }) =>
                    author_name === studentName
                )
                .map(
                  (response: {
                    value: string
                    author_name: string
                    pair_name: string
                    experience: string
                  }) => (
                    <P key={response.value}>
                      <Chip>Regarding {response.pair_name}</Chip>
                      {response.value}
                    </P>
                  )
                )}
              <H3>Feedback from Pairs </H3>
              {(
                sprintWrapUpFormQuestions[
                  "What's the number one thing for your pair to work on?"
                ] || []
              )
                .filter(
                  ({ author_name }: { author_name: string }) =>
                    author_name !== studentName
                )
                .map(
                  (response: {
                    value: string
                    author_name: string
                    experience: string
                  }) => (
                    <P key={response.value}>
                      <Chip>{`From ${response.author_name} (${response.experience}/10)`}</Chip>
                      {response.value}
                    </P>
                  )
                )}
            </ModuleContainer>
          </ModalContainer>
        ) : (
          <ModalContainer>
            <Title>
              <H0>Standards Summary for {studentName}</H0>
            </Title>
            {collatedStandards.map((standard, idx) => (
              <ModuleContainer key={`standard-${idx}`}>
                {(idx < 1 ||
                  (collatedStandards[idx - 1] &&
                    collatedStandards[idx - 1].module_name !==
                      standard.module_name)) && (
                  <ModuleName>{standard.module_name}</ModuleName>
                )}
                {(idx < 1 ||
                  (collatedStandards[idx - 1] &&
                    collatedStandards[idx - 1].gate_level !==
                      standard.gate_level)) && (
                  <H4>
                    {standard.gate_level
                      .toLowerCase()
                      .split("_")
                      .map((word) => word[0].toUpperCase() + word.slice(1))
                      .join(" ")}
                  </H4>
                )}
                <StandardContainer>
                  <Standard standard={standard} />
                  <UL>
                    <LI>{standard.standard}</LI>
                  </UL>
                </StandardContainer>
              </ModuleContainer>
            ))}
          </ModalContainer>
        )}
      </Modal>
    </Container>
  )
}

export default connector(Student)
