import React from "react"
import {
  Container,
  Note,
  Chip,
  ContentContainer,
  LevelContainer,
  Level,
  ToolP,
  ExperienceContainer,
  Answer,
} from "./styles.css"
import { useHistory } from "react-router-dom"
import { FormHistory } from "../../../../global"
import { A, H3, LabelDescription, P } from "../../base/styles.css"
import Title from "../title"
import { formatDistanceToNow } from "date-fns"
import { WHITEBOARD_REFLECTION_QUESTIONS } from "../../../constants"
import { colorGradient } from "../../../utils"

interface Props {
  form: FormHistory
  studentName: string
}

const WhiteboardReflection = ({
  form,
  studentName: studentPreferredName,
}: Props) => {
  const { headers, rows, form_name, module_name, form_number } = form
  const history = useHistory()

  if (!rows || !rows[0]) history.push("/cohorts")
  const submission = (rows as string[][])[0] as string[]
  const questionAnswerSets = WHITEBOARD_REFLECTION_QUESTIONS.map((question) => {
    return {
      ...question,
      answer: submission[
        headers?.findIndex((h) => h === question.name) as number
      ] as string,
    }
  }).filter(
    (question) =>
      ![
        "Your Name",
        "Your Pair's Name",
        "Link to whiteboard",
        "What aspects are still confusing to you because of this sprint?",
        "What's the number one thing you can work on?",
        "What's the number one thing for your pair to work on?",
        "How would you rate this whiteboarding experience?",
        "Is there anything else you'd like to share?",
      ].some((q) => q === question.name)
  )
  const studentName = submission[
    headers?.findIndex((h) => h === "Your Name") as number
  ] as string

  const pairName = submission[
    headers?.findIndex((h) => h === "Your Pair's Name") as number
  ] as string

  const stillConfusing = submission[
    headers?.findIndex(
      (h) =>
        h === "What aspects are still confusing to you because of this sprint?"
    ) as number
  ] as string

  const linkToWhiteboard = submission[
    headers?.findIndex((h) => h === "Link to whiteboard") as number
  ] as string

  const studentImprovement = submission[
    headers?.findIndex(
      (h) => h === "What's the number one thing you can work on?"
    ) as number
  ] as string

  const pairImprovement = submission[
    headers?.findIndex(
      (h) => h === "What's the number one thing for your pair to work on?"
    ) as number
  ] as string

  const pairExpRating = submission[
    headers?.findIndex(
      (h) => h === "How would you rate this whiteboarding experience?"
    ) as number
  ] as string

  const otherDetails = submission[
    headers?.findIndex(
      (h) => h === "Is there anything else you'd like to share?"
    ) as number
  ] as string

  const timestamp = `${
    submission[headers?.findIndex((h) => h === "Timestamp") as number] as string
  } (${formatDistanceToNow(
    new Date(
      submission[
        headers?.findIndex((h) => h === "Timestamp") as number
      ] as string
    ),
    { addSuffix: true }
  )})`

  const row = rows && rows[0] ? rows[0] : []

  return (
    <Container>
      <Title
        title={`${form_name} ${module_name} ${form_number}`}
        author={`Author: ${studentName} ${
          studentName === pairName
            ? "(SOLO'd)"
            : "(Paired With: " + pairName + ")"
        }`}
        timestamp={timestamp}
      />
      <Note>
        <P>
          <Chip>Link to Whiteboard</Chip>
          <A onClick={() => window.open(linkToWhiteboard, "_blank")}>
            {linkToWhiteboard}
          </A>
        </P>
      </Note>
      <Note>
        <ExperienceContainer>
          <Chip>Overall Experience</Chip>
          <H3 style={{ margin: "4px 20px" }}>{pairExpRating}</H3>
          {Array.from(Array(10).keys())
            .map((slot, idx) => colorGradient((1 / (10 - 1)) * (idx + 1)))
            .map((color, idx) => (
              <LevelContainer key={`level-${idx}`}>
                <Level
                  style={
                    +pairExpRating === idx + 1
                      ? {
                          backgroundColor: color,
                        }
                      : {}
                  }
                >
                  <ToolP>
                    {" "}
                    <span>{`Value: ${
                      +pairExpRating === idx + 1 ? +pairExpRating : idx + 1
                    }`}</span>
                  </ToolP>
                </Level>
              </LevelContainer>
            ))}
        </ExperienceContainer>
      </Note>
      <Note>
        <P>
          <Chip>Personal Growth</Chip>
          {studentImprovement}
        </P>
      </Note>
      <Note>
        <P>
          <Chip>Pair Growth</Chip>
          {`Regarding ${pairName}: ${pairImprovement}`}
        </P>
      </Note>
      <Note>
        <P>
          <Chip>Still Confusing</Chip>
          {stillConfusing}
        </P>
      </Note>
      <Note>
        <P>
          <Chip>Other Notes</Chip>
          {otherDetails}
        </P>
      </Note>
      <ContentContainer>
        {questionAnswerSets.map((qa, qaid) => {
          let { high, low } = qa
          const { type } = qa
          if (!high || !low) {
            high = [2, "undefined"]
            low = [1, "undefined"]
          }
          const diff =
            1 / ((((high as any)[0] as number) - (low as any)[0]) as number)
          switch (type) {
            case "scale": {
              return (
                <div key={`${qa.type}-${qaid}`}>
                  <LabelDescription>{qa.name}</LabelDescription>
                  <ExperienceContainer>
                    {Array.from(Array(high[0] || 0).keys())
                      .map((slot, idx) => colorGradient(diff * (idx + 1)))
                      .map((color, idx) => (
                        <LevelContainer key={`level-${idx}`}>
                          <Level
                            style={
                              +qa.answer === idx + 1
                                ? {
                                    backgroundColor: color,
                                  }
                                : {}
                            }
                          >
                            <ToolP>
                              {" "}
                              <span>{`Value: ${
                                +qa.answer === idx + 1 ? +qa.answer : idx + 1
                              }\nLow: ${(low as any)[1]}\nHigh: ${
                                (high as any)[1]
                              }`}</span>
                            </ToolP>
                          </Level>
                        </LevelContainer>
                      ))}
                  </ExperienceContainer>
                </div>
              )
            }
            default: {
              return (
                <div key={`${qa.type}-${qaid}`}>
                  <LabelDescription>{qa.name}</LabelDescription>
                  <div>
                    <Answer>{qa.answer}</Answer>
                  </div>
                </div>
              )
            }
          }
        })}
      </ContentContainer>
    </Container>
  )
}

export default WhiteboardReflection
