import React from "react"
import { Container, Title, Pillar, Circle } from "./styles.css"
import { useHistory } from "react-router-dom"
import { H4 } from "../../base/styles.css"

interface Props {
  T?: number | undefined
  R?: number | undefined
  A?: number | undefined
  G?: number | undefined
}

const FourPillars = (props: Props) => {
  const { T, R, A, G } = props
  const history = useHistory()

  return (
    <Container>
      <Title>Four Pillars</Title>
      <div>
        <Pillar>
          <H4>Technical Ability</H4>
          <Circle>{T}</Circle>
        </Pillar>
        <Pillar>
          <H4>Rapport/ Social</H4>
          <Circle>{R}</Circle>
        </Pillar>
        <Pillar>
          <H4>Accountability/ Professionalism</H4>
          <Circle>{A}</Circle>
        </Pillar>
        <Pillar>
          <H4>Grit/ Self Management</H4>
          <Circle>{G}</Circle>
        </Pillar>
      </div>
    </Container>
  )
}

export default FourPillars
