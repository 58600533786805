import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react"
import { Fade } from "@material-ui/core"
import { StyledSnackBar } from "./styles.css"
import { FixMe } from "../../../global"

const AUTO_DISMISS = 3000
export const SnackBarContext = createContext<FixMe | null>(null)
export const useSnackBar = () => useContext(SnackBarContext)
export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [alerts, setAlerts] = useState<string[]>([])

  useEffect(() => {
    if (alerts.length > 0) {
      const timer = setTimeout(
        () => setAlerts((alerts) => alerts.slice(0, alerts.length - 1)),
        AUTO_DISMISS
      )
      return () => clearTimeout(timer)
    }
  }, [alerts])

  const emitSnackBarAlert = useCallback(
    (content: string) => setAlerts((alerts) => [content, ...alerts]),
    []
  )

  const clearSnackBar = useCallback(() => setAlerts([]), [])

  const value = useMemo(
    () => ({ emitSnackBarAlert, clearSnackBar }),
    [emitSnackBarAlert, clearSnackBar]
  )

  return (
    <SnackBarContext.Provider value={value}>
      {children}
      {alerts.map((alert, idx) => (
        <StyledSnackBar
          key={`alert-${idx}-${alert}`}
          open={true}
          message={alert}
          TransitionComponent={Fade}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={AUTO_DISMISS}
        />
      ))}
    </SnackBarContext.Provider>
  )
}
