import React, { useState } from "react"
import {
  Container,
  Spacer,
  MenuItems,
  MenuItem,
  NavIconContainer,
} from "./styles.css"

import { useHistory } from "react-router-dom"
import LightLogo from "../../assets/hr-logo.png"
import DarkLogo from "../../assets/hr-logo-dark.png"

interface NavState {
  overview: string
  cohorts: string
  settings: string
}

const resetNavState = {
  overview: "",
  cohorts: "",
  settings: "",
}

function SideNav({ theme }: { theme: "light" | "dark" }) {
  const [navState, setNavState] = useState(resetNavState)
  const handleMenuSelect = (menuItem: keyof NavState) => {
    setNavState({
      ...resetNavState,
      [menuItem]: navState[menuItem].length ? "" : "active",
    })
  }

  const history = useHistory()

  return (
    <Container>
      <NavIconContainer>
        {theme === "light" ? (
          <img
            style={{ width: "70px" }}
            src={LightLogo}
            onClick={() => history.push("/overview")}
          />
        ) : (
          <img
            style={{ width: "70px" }}
            src={DarkLogo}
            onClick={() => history.push("/overview")}
          />
        )}
      </NavIconContainer>
      <Spacer />
      <MenuItems>
        <MenuItem
          onClick={() => {
            history.push("/overview")
            handleMenuSelect("overview")
          }}
          className={`${navState.overview}`}
        >
          Overview
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push("/cohorts")
            handleMenuSelect("cohorts")
          }}
          className={`${navState.cohorts}`}
        >
          Cohorts
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push("/settings")
            handleMenuSelect("settings")
          }}
          className={`${navState.settings}`}
        >
          Settings
        </MenuItem>
      </MenuItems>
    </Container>
  )
}

export default SideNav
