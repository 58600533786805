import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
`
export const Title = styled.div`
  width: 100%;
  > h1 {
    text-align: center;
    margin: 40px 0;
  }
`

export const Chip = styled.span`
  border: 1px solid ${({ theme }) => theme.borderColor};
  padding: 2px 10px;
  margin-right: 10px;
  border-radius: 20px;
`

export const ModalContainer = styled.div`
  top: 100px;
  left: 100px;
  padding-left: 20px;
  width: calc(100% - 200px);
  height: 80%;
  position: absolute;
  overflow: auto;
  background: ${({ theme }) => theme.backgroundColor.primary};
`

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-areas: "p  tps";
  min-height: calc(100vh - 30%);
  > table:first-child {
    width: 80%;
  }
  > table:nth-child(2) {
  }
`

export const ModuleName = styled.div`
  width: 98%;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  display: flex;
  flex-direction: row-reverse;
  font-family: ${({ theme }) => theme.font.family.lato};
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.text.normal};
  padding-bottom: 10px;
`
export const StandardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > ul {
    max-width: 800px;
    > li {
      line-height: 1.25;
      font-family: ${({ theme }) => theme.font.family.lora};
    }
  }
`
export const ModuleContainer = styled.div`
  > p {
    margin: 10px 0;
  }
`
