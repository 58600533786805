import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  margin-top: 30px;
  > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
`

export const Pillar = styled.div`
  width: 100%;
  text-align: center;
  margin: auto;
  h4 {
    word-break: break-word;
  }
`

export const Title = styled.div`
  width: 98%;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  display: flex;
  flex-direction: row-reverse;
  font-family: ${({ theme }) => theme.font.family.lato};
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.text.normal};
  padding-bottom: 10px;
`

export const Circle = styled.div`
  width: 35px;
  height: 35px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  font-family: ${({ theme }) => theme.font.family.lato};
  font-size: ${({ theme }) => theme.font.size.m};
  color: ${({ theme }) => theme.color.text.normal};
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`
