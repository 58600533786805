import styled from "styled-components"
import { Snackbar } from "@material-ui/core"

export const StyledSnackBar = styled(Snackbar)`
  .MuiSnackbarContent-root {
    background-color: ${({ theme }) => theme.backgroundColor.tertiary};
    color: ${({ theme }) => theme.color.text.secondary};
    font-family: ${({ theme }) => theme.font.family.lato};
    font-size: ${({ theme }) => theme.font.size.m};
  }
`
