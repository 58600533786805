type Stage = "sandbox" | "prod" | "stage"

//TODO: pass in as environment variable for STAGE and DEBUG
export const STAGE: Stage = "prod"

export const DEBUG = false

export const LOG_LEVEL = {
  info: { prefix: "[INFO]", func: console.log }, // general info logs. can be committed
  error: { prefix: "[ERROR]", func: console.error }, // general error logs for added verbosity when developing. can be committed
  highlight: { prefix: "[⭐️]", func: console.info }, // stand-out logs for special cases. should not be committed
}

export const SCOPE = [
  "https://www.googleapis.com/auth/spreadsheets",
  "https://www.googleapis.com/auth/forms",
  "https://www.googleapis.com/auth/drive",
  "https://www.googleapis.com/auth/forms.currentonly",
  "https://www.googleapis.com/auth/calendar",
].join(" ")

export const DISCOVERY_DOCS = [
  "https://sheets.googleapis.com/$discovery/rest?version=v4",
]

//NOTE: adjacency list of all application users and associated campuses
export const USER_DATA_SOURCES = "1lexNJY28ZxGmYCxvJj-mPIBhfhOo60-O6OZ2Sc_eRzg"

//NOTE: apps script host for individual script invocations
export const GCP_PROJECT_CLIENT_ID =
  "920031188616-tjcr9u7iajgc3j1sllsmm2m18ctokthj.apps.googleusercontent.com"

//NOTE: adjacency list of curriculum modules and relevant standards
export const CURRICULUM_ID = "1yFTxncOzeUVtIWN1tajpgbO-bQH_cbdi_vQpdGctMoQ"

//NOTE: adjacency list of all SEI prompts (whiteboarding, toy problems, self assessments)
export const PROMPTS_ID = "1dKKTBziqARjJuwWUpGqfue3ivCaOghVyywHmqRXrN5w"

//TODO: These deployment ids are updated with every new deployment. Consider
//dynamic access to these variables in adjacency list

//NOTE: apps script deployment id
export const GEN_STAFF_NOTES_APP_SCRIPT_ID =
  "AKfycbwG92GNJ1oqdlYkxIXRMtwhcZSN-wtsfKD_79oPmSmxHxzgaJ6llUK26zeQZ-RnQPs"

//NOTE: apps script deployment id
export const GEN_PAIRING_OBSERVATION_APP_SCRIPT_ID =
  "AKfycbzmG-TmkQa9nYG0W7EU97jd3okMkzaROqrY2DMiWFfLLWkAZFNui5SWy8tSsO_S6h4J"

//NOTE: apps script deployment id
export const GEN_SPRINT_WRAP_UP_APP_SCRIPT_ID =
  "AKfycbyEk4TlXPNy8jswLGxeFLIv3e3ySoGXZl3EEWCY7WeVM171MLAMDeb3jz8nGAjcO_Y"

//NOTE: apps script deployment id
export const GEN_HELP_DESK_APP_SCRIPT_ID =
  "AKfycbyLSNyRrukeWPTwxrZYRCu-tLjRluCZnLt646XMuSwzXj2Q3j7FrN0JJQtyfmmHww6D"

//NOTE: apps script deployment id
export const GEN_WHITEBOARD_REFLECTION_APP_SCRIPT_ID =
  "AKfycbyA7vRRadwIyefreFtf7UHAE1GnesUhr9CpgXGMq-nmwTRH0JI37ZS0_BB_-n34WOU"

//NOTE: apps script deployment id
export const SPRINT_WRAP_UP_QUESTIONS = [
  {
    name: "Were you able to meet the Bare Minimum Requirements (BMR)?",
    description:
      "Did you complete BMR specified in the Learn app and make milestone commits?",
    type: "select-one",
    options: [
      "Not Attempted",
      "Incomplete",
      "Complete IF given 6 hours extra hacking time",
      "Complete",
    ],
  },
  {
    name: "What was your Driver/Navigator ratio?",
    description:
      "What percentage of the time did you feel you were either a Driver or a Navigator?",
    type: "select-one",
    options: [
      "I solo'd",
      "Drive: 0% / Navigate: 100%",
      "Drive: 15% / Navigate: 85%",
      "Drive: 30% / Navigate: 70%",
      "Drive: 45% / Navigate: 55%",
      "Drive: 60% / Navigate: 40%",
      "Drive: 75% / Navigate: 15%",
      "Drive: 100% / Navigate: 0%",
    ],
  },
  {
    name: "Did you fall into any Anti-Patterns?",
    description:
      "Did you ever find yourself feeling in any of the following ways?",
    type: "multi-select",
    options: [
      "Overbearing",
      "Resistant",
      "Offensive",
      "Too Quiet",
      "Dismissive",
      "None",
      "I solo'd",
    ],
  },
  {
    name: "Did your pair fall into any Anti-Patterns?",
    description:
      "Did you ever find your pair acting in any of the following ways?",
    type: "multi-select",
    options: [
      "Overbearing",
      "Resistant",
      "Offensive",
      "Too Quiet",
      "Dismissive",
      "None",
      "I solo'd",
    ],
  },
  {
    name: "What was your comfort with the material?",
    description:
      "Despite the material being challenging, how comfortable were you navigating those challenges?",
    type: "scale",
    low: [1, "Unproductive and\n uncomfortable"],
    high: [5, "Very comfortable\nand productive"],
  },
  {
    name: "What was your comfort with how time was allocated?",
    description:
      "Do you feel good about how time was spent this sprint? Remember, it's good to go down a wrong path, if you are  productive with your time",
    type: "scale",
    low: [1, "Wasted time"],
    high: [5, "Very productive"],
  },
  {
    name: "How engaged did you feel this sprint?",
    description:
      "Regardless of driver/navigator role, how did you feel you were a part of the problem solving process?",
    type: "scale",
    low: [1, "Didn't\nhang on"],
    high: [5, "Followed\neverything"],
  },
  {
    name: "Were you able to complete the Advanced Content?",
    description:
      "Did you complete the Advanced Content specified in the Learn app?",
    type: "select-one",
    options: [
      "Not Attempted",
      "Incomplete",
      "Complete IF 6+ Hours Extra Hacking Time",
      "Complete",
    ],
  },
  {
    name: "Were you able to complete Nightmare Mode?",
    description: "Did you complete Nightmare Mode specified in the Learn app?",
    type: "select-one",
    options: [
      "Not Attempted",
      "Incomplete",
      "Complete IF 6+ Hours Extra Hacking Time",
      "Complete",
    ],
  },
  {
    name: "What aspects are still confusing to you because of this sprint?",
    description:
      "If there's anything that feels fuzzy from your sprint experience, try and detail it here.",
    type: "long-text",
  },
  {
    name: "As a junior, were you clear about being lost?",
    description:
      "If you felt junior and felt lost during this sprint, were you able to make this clear?",
    type: "scale",
    low: [1, "I only\ninternalized"],
    high: [5, "I communicated\neffectively"],
  },
  {
    name: "As a junior, were you able to drive enough?",
    description:
      "If you felt junior, did you feel you drove enough while your pair stayed engaged with you?",
    type: "scale",
    low: [1, "Low driving\nand engagement"],
    high: [5, "Sufficient driving\nand engaged"],
  },
  {
    name: "As a junior, did you prevent your 'senior' partner from dominating the problem solving process too much?",
    description:
      "If you felt junior, did you ensure you remained an active participant in the problem solving process?",
    type: "scale",
    low: [1, "Inactive in\nProblem Solving\nProcess"],
    high: [5, "Very Active\nin Problem Solving\nProcess"],
  },
  {
    name: "As the senior, did you pick a good speed for your junior to move with you?",
    description:
      "If you felt senior, did you remain mindful of your junior's ability to join you in the problem solving process?",
    type: "scale",
    low: [1, "I towed my pair"],
    high: [5, "I moved at a productive\npace for my pair"],
  },
  {
    name: "As the senior, did you articulate your thoughts clearly?",
    description:
      "If you felt senior, were you mindful to speak clear technical communications that remove ambiguity wherever possible?",
    type: "scale",
    low: [1, "I had low or ambiguous\ncommunication"],
    high: [5, "I practiced precise and\neffective comunication"],
  },
  {
    name: "As the senior, did you express ideas in another text buffer while driving?",
    description:
      "If you felt senior and were driving, did you prevent drivigating by whiteboarding ideas or illustrating your thoughts elsewhere outside the sprint code?",
    type: "scale",
    low: [1, "I drivigated\nin order to explain"],
    high: [5, "I only wrote code\nfully agreed upon"],
  },
  {
    name: "What's the number one thing you can work on?",
    description:
      "From this experience, what's one way you feel you can improve?",
    type: "long-text",
  },
  {
    name: "What's the number one thing for your pair to work on?",
    description:
      "From this experience, what's one way you feel your pair can improve?",
    type: "long-text",
  },
  {
    name: "How would you rate this pair programming experience?",
    description:
      "What was your overall experience with this sprint including any sprint partner?",
    type: "scale",
    low: [
      1,
      "I would not\nrecommend my\npair programming\nexperience at\nthis time",
    ],
    high: [
      10,
      "I would highly\nrecommend my\npair programing\nexperience at\nthis time",
    ],
  },
  {
    name: "Is there anything else you'd like to share?",
    description:
      "If you have any other items you'd like to share, please let us know here",
    type: "long-text",
  },
]

export const WHITEBOARD_REFLECTION_QUESTIONS = [
  {
    name: "Link to whiteboard",
    description: "If you used excalidraw.com you can export to sharable link ",
    type: "long-text",
    is_required: true,
  },
  {
    name: "What was your comfort with the problem?",
    description:
      "Despite the session being challenging, how comfortable were you finding ways to tackle the problem?",
    type: "scale",
    low: [1, "Unproductive and\n uncomfortable"],
    high: [5, "Very comfortable\nand productive"],
    is_required: true,
  },
  {
    name: "Did you Proctor or Whiteboard?",
    description: "Which role did you play in this session",
    type: "select-one",
    options: ["Proctor", "Whiteboarder"],
    is_required: true,
  },
  {
    name: "As a proctor, did you give clear instructions?",
    description:
      "If you were the person whiteboarding this session, you may skip this question",
    type: "scale",
    low: [1, "Gave no/wrong\ninstructions"],
    high: [5, "High clarity\nand precision"],
    is_required: false,
  },
  {
    name: "As a proctor, were you firm but fair?",
    description:
      "If you were the person whiteboarding this session, you may skip this question",
    type: "scale",
    low: [1, "Helped too much/ \nwas too brief"],
    high: [5, "Collaborated the/nright amount"],
    is_required: false,
  },
  {
    name: "As a proctor, did you ask good questions?",
    description:
      "If you were the person whiteboarding this session, you may skip this question",
    type: "scale",
    low: [1, "Asked no questions/ \ncounter-productive questions"],
    high: [5, "Well placed,\nproductive questions"],
    is_required: false,
  },
  {
    name: "As the person whiteboarding, did you arrive at a solution?",
    description: "If you proctored this session, you may skip this question",
    type: "scale",
    low: [1, "Far from workable\nnaive solution"],
    high: [5, "Workable optimized solution"],
    is_required: false,
  },
  {
    name: "As the person whiteboarding, did you include your proctor in your problem solving process?",
    description: "If you proctored this session, you may skip this question",
    type: "scale",
    low: [1, "All problem solving\nwas internal"],
    high: [5, "I let a productive\nconversation"],
    is_required: false,
  },
  {
    name: "As the person whiteboarding, did you ask good questions?",
    description: "If you proctored this session, you may skip this question",
    type: "scale",
    low: [1, "Asked no questions/ \ncounter-productive questions"],
    high: [5, "Well placed,\nproductive questions"],
    is_required: false,
  },
  {
    name: "What's the number one thing you can work on?",
    description:
      "From this experience, what's one way you feel you can improve?",
    type: "long-text",
    is_required: true,
  },
  {
    name: "What's the number one thing for your pair to work on?",
    description:
      "From this experience, what's one way you feel your pair can improve?",
    type: "long-text",
    is_required: true,
  },
  {
    name: "How would you rate this whiteboarding experience?",
    description:
      "What was your overall experience in this session including intaraction with others?",
    type: "scale",
    low: [1, "I felt poorly about\nmy whiteboarding\nexperience this\ntime"],
    high: [10, "I really enjoyed my\nwhiteboarding experience\nthis time"],
    is_required: true,
  },
  {
    name: "Is there anything else you'd like to share?",
    description:
      "If you have any other items you'd like to share, please let us know here",
    type: "long-text",
    is_required: true,
  },
]
