import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { FixMe, ReduxStatus, GoogleID, LearningStandard } from "../../../global"
import { curriculum } from "../../services/api"

interface InitialCurriculumState {
  status: ReduxStatus
  msg: string | null
  data: LearningStandard[]
}

const initialState: InitialCurriculumState = {
  status: "pending",
  msg: null,
  data: [],
}

export const fetchCurriculum = createAsyncThunk(
  "curriculum/fetch",
  async () => {
    return await curriculum
      .fetch()
      .catch(({ message }) =>
        Promise.reject("Could not retrieve Curriculum. " + message)
      )
  }
)

const curriculumSlice = createSlice({
  name: "curriculum",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchCurriculum.fulfilled,
      (state, action: { payload: FixMe }) => {
        state.status = "ready"
        state.data = action.payload
        state.msg = null
      }
    )
    builder.addCase(fetchCurriculum.pending, (state) => {
      state.status = "pending"
    })
    builder.addCase(fetchCurriculum.rejected, (state, action) => {
      state.status = "error"
      state.msg = action.error.message + ""
    })
  },
})

export default curriculumSlice.reducer
