import formatDistanceToNow from "date-fns/formatDistanceToNow"
import { DateString } from "../../global"
import { DEBUG, LOG_LEVEL } from "../constants"

export const logger =
  (
    debug: boolean,
    {
      func: log,
      prefix,
    }: { func: (str: string, ...args: any) => any; prefix: string }
  ) =>
  (msg: string, ...args: any[]) =>
    debug ? log(`${prefix} ${msg}`, ...args) : null

export const info = logger(DEBUG, LOG_LEVEL.info)
export const error = logger(DEBUG, LOG_LEVEL.error)
export const highlight = logger(DEBUG, LOG_LEVEL.highlight)

export const serializeString: (input: string) => string = (input) =>
  input
    .toLowerCase()
    .split(" ")
    .join("")
    .replace(/[&\/\\#,_+()$~%.’'":*?<>{}]/g, "")

export const getCurrentDate = () => {
  const currDate = new Date()
  const month = String(currDate.getMonth() + 1)
  const day = String(currDate.getDate())
  const year = currDate.getFullYear().toString()

  return `${month}/${day}/${year}`
}

export const dateRank = (date: DateString) => {
  const [month, day, year] = date.split("/").map((datePart: string) => {
    if (datePart.length < 2) {
      return "0" + datePart
    } else {
      return datePart
    }
  })

  return parseInt(year + month + day)
}

export const stringSimilarity = (s1: string, s2: string) => {
  const editDistance = (s1: string, s2: string) => {
    s1 = s1.toLowerCase()
    s2 = s2.toLowerCase()

    const costs = []
    for (let i = 0; i <= s1.length; i++) {
      let lastValue = i
      for (let j = 0; j <= s2.length; j++) {
        if (i == 0) costs[j] = j
        else {
          if (j > 0) {
            let newValue = costs[j - 1]
            if (s1.charAt(i - 1) != s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
            costs[j - 1] = lastValue
            lastValue = newValue
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue
    }
    return costs[s2.length]
  }

  let longer = s1
  let shorter = s2
  if (s1.length < s2.length) {
    longer = s2
    shorter = s1
  }
  const longerLength = longer.length
  if (longerLength == 0) {
    return 1.0
  }
  return (
    (longerLength - editDistance(longer, shorter)) /
    parseFloat(longerLength + "")
  )
}

export const compareString = (
  stringOne: string,
  stringTwo: string,
  threshold = 0.9
) => {
  const sr1 = serializeString(stringOne)
  const sr2 = serializeString(stringTwo)
  return stringSimilarity(sr1, sr2) >= threshold
}

export const formatTime = (date: string, suffix = "") => {
  const space = suffix.length > 0 ? " " : ""
  return formatDistanceToNow(new Date(date)) + space + suffix
}

export const sortListByDate = (
  dateProperty: "created_at" | "updated_at",
  sortType: "ASC" | "DESC",
  collection: any[]
) => {
  if (sortType === "ASC") {
    return [...collection].sort(
      (a, b) =>
        new Date(a[dateProperty]).getTime() -
        new Date(b[dateProperty]).getTime()
    )
  } else {
    return [...collection]
      .sort(
        (a, b) =>
          new Date(a[dateProperty]).getTime() -
          new Date(b[dateProperty]).getTime()
      )
      .reverse()
  }
}

// outputs color from red to green 0 - 100
export const colorGradient = (fadeFraction: number) => {
  let color1 = {
    red: 217,
    green: 83,
    blue: 79,
  }
  let color2 = {
    red: 240,
    green: 173,
    blue: 78,
  }
  let fade = fadeFraction

  fade = fade * 2

  if (fade >= 1) {
    fade -= 1
    color1 = color2
    color2 = {
      red: 92,
      green: 184,
      blue: 91,
    }
  }

  const diffRed = color2.red - color1.red
  const diffGreen = color2.green - color1.green
  const diffBlue = color2.blue - color1.blue

  const gradient = {
    red: parseInt(Math.floor(color1.red + diffRed * fade) + "", 10),
    green: parseInt(Math.floor(color1.green + diffGreen * fade) + "", 10),
    blue: parseInt(Math.floor(color1.blue + diffBlue * fade) + "", 10),
  }

  return (
    "rgb(" + gradient.red + "," + gradient.green + "," + gradient.blue + ")"
  )
}
