import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { FixMe, ReduxStatus, GoogleID, Cohort } from "../../../global"
import { cohort } from "../../services/api"

interface InitiaCohortsState {
  status: ReduxStatus
  msg: string | null
  data: Cohort[]
}

const initialState: InitiaCohortsState = {
  status: "pending",
  msg: null,
  data: [],
}

export const fetchCohorts = createAsyncThunk(
  "cohorts/fetch",
  async ({ campus_data_ids: campusIds }: { campus_data_ids: GoogleID[] }) => {
    return await Promise.all(
      campusIds.map((campus_data_id) => cohort.fetch(campus_data_id))
    )
      .then((forms) => forms.reduce((acc, el) => acc.concat(el), []))
      .catch(({ message }: { message: string }) =>
        Promise.reject("Could not retrieve Cohort. " + message)
      )
  }
)

export const addFormsToCohorts = createAsyncThunk(
  "cohorts/addFormSubmissions",
  async (cohorts: Cohort[]) => {
    return await cohort
      .fetchForms(cohorts)
      .catch(({ message }) =>
        Promise.reject("Could not add form submissions. " + message)
      )
  }
)

const cohortsSlice = createSlice({
  name: "cohorts",
  initialState,
  reducers: {
    setStudents(state, action) {
      state.status = "ready"
      state.data = action.payload
      state.msg = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchCohorts.fulfilled,
      (state, action: { payload: FixMe }) => {
        state.status = "pending"
        state.data = action.payload
        state.msg = null
      }
    )
    builder.addCase(fetchCohorts.pending, (state) => {
      state.status = "pending"
    })
    builder.addCase(fetchCohorts.rejected, (state, action) => {
      state.status = "error"
      state.msg = action.error.message + ""
    })
    builder.addCase(
      addFormsToCohorts.fulfilled,
      (state, action: { payload: FixMe }) => {
        state.status = "ready"
        state.data = action.payload
        state.msg = null
      }
    )
    builder.addCase(addFormsToCohorts.pending, (state) => {
      state.status = "pending"
    })
    builder.addCase(addFormsToCohorts.rejected, (state, action) => {
      state.status = "error"
      state.msg = action.error.message + ""
    })
  },
})

export const { setStudents } = cohortsSlice.actions
export default cohortsSlice.reducer
