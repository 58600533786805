import styled from "styled-components"
import { H2 } from "../../components/base/styles.css"

export const Container = styled.div`
  height: 100%;
  width: 100%;
`
export const CohortContainer = styled.div`
  max-width: 90%;
`
export const CohortDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div button {
    margin-left: 20px;
  }
`

export const ModalContainer = styled.div`
  top: 100px;
  left: 100px;
  width: calc(100% - 200px);
  height: 80%;
  position: absolute;
  overflow: auto;
  background: ${({ theme }) => theme.backgroundColor.primary};
`

export const Title = styled.div`
  width: 100%;
  > h1 {
    text-align: center;
    margin: 40px 0;
  }
`
export const Steps = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`
export const Step = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  > button,
  select {
    margin-top: 20px;
  }
`

export const StepTitle = styled(H2)`
  font-size: ${({ theme }) => theme.font.size.m};
  font-family: ${({ theme }) => theme.font.family.lato};
  margin-left: 15px;
`

export const StepNumber = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.tertiary};
  border-radius: 50%;
  height: 37px;
  width: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.font.size.m};
  font-family: ${({ theme }) => theme.font.family.lato};
  color: ${({ theme }) => theme.color.text.secondary};
`
