/* global gapi */
import { FixMe, GoogleID, LearningStandard, SpreadsheetAPI } from "../../global"
import { DISCOVERY_DOCS, GCP_PROJECT_CLIENT_ID, SCOPE } from "../constants"
import _ from "lodash"
import { info } from "../utils"

export const loadClienAuthLibrary = () =>
  new Promise((resolve, reject) => {
    if (gapi) {
      gapi.load("client:auth2", () => resolve("client:auth2 loaded"))
    } else {
      reject("client:auth2 failed to load")
    }
  })
export const loadAppScriptLibrary = () =>
  new Promise((resolve, reject) => {
    if (gapi && gapi.client) {
      gapi.client.load("script", "v1", () => resolve("appscript loaded"))
    } else {
      reject("appscript failed to load")
    }
  })
export const loadCalendarLibrary = () =>
  new Promise((resolve, reject) => {
    if (gapi && gapi.client) {
      gapi.client.load("calendar", "v3", () => resolve("calendar loaded"))
    } else {
      reject("calendar failed to load")
    }
  })

export const initGapi = async () => {
  return await gapi.client
    .init({
      clientId: GCP_PROJECT_CLIENT_ID,
      scope: SCOPE,
      discoveryDocs: DISCOVERY_DOCS,
    })
    .then(() => {
      const GoogleAuth = gapi.auth2.getAuthInstance()
      if (!GoogleAuth.isSignedIn.get()) {
        GoogleAuth.signIn()
        GoogleAuth.isSignedIn.listen(() => {
          console.log("Is Signed In: ", GoogleAuth.isSignedIn.get())
          const user = GoogleAuth.currentUser.get().getBasicProfile()
          return {
            first_name: user.getGivenName(),
            last_name: user.getFamilyName(),
            email: user.getEmail(),
            profile_url: user.getImageUrl(),
          }
        })
      } else {
        console.log("Is Signed In: ", GoogleAuth.isSignedIn.get())
        const user = GoogleAuth.currentUser.get().getBasicProfile()
        return {
          first_name: user.getGivenName(),
          last_name: user.getFamilyName(),
          email: user.getEmail(),
          profile_url: user.getImageUrl(),
        }
      }
      window.gapi = gapi
    })
}

export const verifyAuth = async (cb: FixMe) => {
  if (window.gapi) {
    const GoogleAuth = gapi.auth2.getAuthInstance()
    const isSignedIn = GoogleAuth.isSignedIn.get()
    if (!isSignedIn) {
      await GoogleAuth.signIn()
    }
    //TODO: fix this function
    cb()
  } else {
    cb("Error: Could not verify auth")
  }
}

export const execAppScript = async (
  formName: string,
  formNumber: number,
  cohortName: string,
  students: string[],
  moduleName: string,
  staffNotesTemplateFormId: GoogleID,
  destinationFolderId: GoogleID,
  curriculumQuestions: LearningStandard[],
  appScriptID: GoogleID,
  userEmailGroup: string,
  existingResponseId?: GoogleID
) => {
  if (window.gapi) {
    return gapi.client.script.scripts
      .run({
        scriptId: appScriptID,
        resource: {
          function: "generateForm",
          parameters: [
            formName,
            formNumber,
            cohortName,
            students,
            moduleName,
            staffNotesTemplateFormId,
            destinationFolderId,
            curriculumQuestions,
            userEmailGroup,
            existingResponseId,
          ],
        },
      })
      .then((resp) => {
        try {
          info("execAppScript resp:", resp)
          const { response, done } = JSON.parse(resp.body)
          if (!done) throw new Error("Done is falsy")
          return response.result
        } catch (err) {
          throw new Error(`AppScript Failure: ${err}`)
        }
      })
      .catch((err) => {
        console.error("☠️ APPS SCRIPT FAIL ☠️")
        console.error(err)
        Promise.reject(err)
      })
  }
}

export const fetchCalendar = async (calendarId: GoogleID) => {
  if (window.gapi) {
    let calendar
    try {
      calendar = await gapi.client.calendar.events.list({
        calendarId,
        singleEvents: true,
        orderBy: "starttime",
        timeMin: new Date().toISOString(),
      })
    } catch (e) {
      console.error("fetch calendar", e)
    }
    const data = _.get(calendar, "body", "{}")
    return JSON.parse(data) as gapi.client.calendar.Events
  }
}

//TODO document and error handle
export const fetchSheets = async (spreadsheetId: string, range: FixMe) => {
  if (window.gapi) {
    const rawData = await gapi.client.sheets.spreadsheets.values.get({
      spreadsheetId,
      range,
    })
    return JSON.parse(rawData.body)
  }
}

export const fetchSheetsBatch = async (
  spreadsheetId: string,
  ranges: FixMe
) => {
  if (window.gapi) {
    const rawData = await gapi.client.sheets.spreadsheets.values.batchGet({
      spreadsheetId,
      ranges,
    })
    return JSON.parse(rawData.body) as SpreadsheetAPI.BatchGetResponses
  } else {
    throw new Error(
      `GAPI Not Loaded. Cannot load from spreadsheet_id: [${spreadsheetId}] for ranges [${ranges} ]`
    )
  }
}

export const loadScript = (src: string) => {
  return new Promise(function (resolve, reject) {
    const script = document.createElement("script")
    script.src = src
    script.addEventListener("load", function () {
      resolve("success")
    })
    script.addEventListener("error", function (e) {
      reject(e)
    })
    document.body.appendChild(script)
  })
}
