import React, { useEffect, useState } from "react"
import { useSnackBar } from "../../hooks/useSnackbar"
import { useRouteMatch, Route, Switch, useHistory } from "react-router-dom"
import { CloudQueue } from "@material-ui/icons"

import { connect, ConnectedProps } from "react-redux"
import { addFormsToCohorts, fetchCohorts } from "./cohortSlice"

import {
  Container,
  CohortContainer,
  CohortDetails,
  ModalContainer,
  Title,
  Steps,
  Step,
  StepTitle,
  StepNumber,
} from "./styles.css"

import {
  ReduxStatus,
  ReduxMsg,
  User,
  LearningStandard,
  Cohort as ICohort,
} from "../../../global"

import Modal from "@material-ui/core/Modal"
import PageHeader from "../../components/pageHeader"
import Cohort from "./cohort"
import { Button, H0, Label, P, Select } from "../../components/base/styles.css"
import CohortCalendar from "./cohortCalendar"

type FixMe = any

const mapState = ({
  cohorts,
  curriculum,
  user_profile: userProfile,
}: {
  cohorts: { status: ReduxStatus; data: ICohort[]; msg: ReduxMsg }
  curriculum: { status: ReduxStatus; data: LearningStandard[][]; msg: ReduxMsg }
  user_profile: { status: ReduxStatus; data: User; msg: ReduxMsg }
}) => ({
  cohorts,
  userProfile,
  curriculum,
})

const mapDispatch = { fetchCohorts, addFormsToCohorts }
const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>

function Cohorts({
  cohorts: { status: cohorts_status, msg: cohorts_msg, data: cohorts },
  curriculum: {
    status: curriculum_status,
    msg: curriculum_msg,
    data: curriculum,
  },
  userProfile: { status: userProfile_status, data: userProfile },
  fetchCohorts,
  addFormsToCohorts,
}: PropsFromRedux) {
  const match = useRouteMatch()
  const history = useHistory()
  const { campus_data_ids } = userProfile || "userProfile is undefined"
  const [isInit, setIsInit] = useState(false)

  useEffect(() => {
    const loadCohorts = async () => {
      setIsInit(true)
      const { payload: cohorts } = (await fetchCohorts({
        campus_data_ids,
      })) as { payload: ICohort[] }
      await addFormsToCohorts(cohorts)
    }
    if (userProfile_status !== "ready") history.push("/")
    if (!isInit) loadCohorts()
  }, [cohorts_status, isInit])
  return (
    <Switch>
      <Route path={`${match.path}/:cohort`}>
        <Container>
          <Cohort />
        </Container>
      </Route>
      <Route path={`${match.path}/`}>
        <Container>
          <PageHeader title="Cohorts" pageActions={[]} />
          {cohorts.map((cohort, idx) => (
            <CohortContainer key={idx}>
              <CohortDetails>
                <H0>{cohort.name}</H0>
                <div>
                  <Button
                    onClick={() =>
                      window.open(
                        `https://drive.google.com/drive/u/0/folders/${cohort.gdrive_id}`,
                        "_blank"
                      )
                    }
                  >
                    <CloudQueue />
                  </Button>
                  <Button
                    onClick={() =>
                      history.push(`/cohorts/${cohort.name.toLowerCase()}`)
                    }
                  >
                    View Cohort
                  </Button>
                </div>
              </CohortDetails>

              <CohortCalendar calendar={cohort.calendar} />
            </CohortContainer>
          ))}
        </Container>
      </Route>
    </Switch>
  )
}

export default connector(Cohorts)
