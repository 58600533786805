import React, { useEffect } from "react"
import { useSnackBar } from "../../hooks/useSnackbar"

import { compareString } from "../../utils"

import { connect, ConnectedProps } from "react-redux"

import Loading from "../../components/base/loading"
import { Container } from "./styles.css"

import PageHeader from "../../components/pageHeader"
import { FixMe } from "../../../global"

const mapState = ({}: FixMe) => ({})

const mapDispatch = {}
const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux

function Settings({}: Props) {
  return (
    <Container>
      <PageHeader title="Settings" pageActions={[]} />
    </Container>
  )
}

export default connector(Settings)
