import styled from "styled-components"

export const Container = styled.div`
  height: 100%;
  width: 95%;
  display: flex;
  justify-content: space-around;
`

export const ListContainer = styled.div`
  height: 100%;
  width: 100%;
`

export const ApplicationsEmpty = styled.div`
  width: 80%;
  border: 2px dashed ${({ theme }) => theme.color.text.normal};
  border-radius: 7px;
  margin: 10px;
  padding: 30px;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > p {
    max-width: 800px;
    margin-bottom: 30px;
  }
  > div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > p {
      margin: auto 30px;
    }
  }
`
