import styled from "styled-components"

export const Container = styled.div`
  height: 35px;
  width: 35px;
  margin-right: 20px;
  margin-bottom: 4px;
`

export const UserProfileIcon = styled.div`
  height: 35px;
  width: 35px;
  background-color: ${({ theme }) => theme.backgroundColor.primary};
  font-family: ${({ theme }) => theme.font.family.lato};
  font-size: ${({ theme }) => theme.font.size.s};
  color: ${({ theme }) => theme.color.text.normal};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.text.normal};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
`
export const UserProfileMenu = styled.div`
  height: 100px;
  float: right;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.backgroundColor.primary};
  font-family: ${({ theme }) => theme.font.family.lato};
  color: ${({ theme }) => theme.color.text.normal};
  position: relative;
  top: 10px;
  min-width: 150px;
  display: table;
  border: 1px solid ${({ theme }) => theme.color.text.normal};
  hr {
    margin: 1rem 0 0.5rem 0;
  }
  ul {
    margin: 0;
    & > li {
      margin: 0;
      list-style: none;
      cursor: pointer;
      height: 20px;
      display: flex;
      align-items: center;
      padding: 0.5rem 0;
      &:hover {
        background-color: ${({ theme }) => theme.backgroundColor.secondary};
      }
      &:not(:last-child) {
      }
    }
  }

  .user {
    &_name {
      margin-bottom: 0.25rem;
    }
    &_email {
      font-weight: ${({ theme }) => theme.font.weight.light};
      font-size: ${({ theme }) => theme.font.size.s};
    }
  }
`
