import styled from "styled-components"

export const Container = styled.div`
  width: 98%;
  margin-bottom: 80px;
  > div:nth-child(3) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`
export const Note = styled.div`
  width: 100%;
`

export const Chip = styled.span`
  border: 1px solid ${({ theme }) => theme.borderColor};
  padding: 2px 10px;
  margin-right: 10px;
  border-radius: 20px;
`
export const StudentContainer = styled.div`
  width: 48%;
`
