import React from "react"
import { Container } from "./styles.css"
import { useHistory } from "react-router-dom"
import { ISOTimestamp } from "../../../../global"
import { H2, H3, P } from "../../base/styles.css"

interface Props {
  title: string
  author: string
  timestamp: ISOTimestamp
}

const Title = (props: Props) => {
  const { title, author, timestamp } = props
  const history = useHistory()

  if (!title) return null

  const style = {
    display: "flex",
    alignItems: "center",
  }

  return (
    <Container>
      <H2>{title}</H2>
      <H3>{author}</H3>
      <H3>{timestamp}</H3>
    </Container>
  )
}

export default Title
