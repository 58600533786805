import React from "react"
import { Container, Note, Chip, StudentContainer } from "./styles.css"
import { useHistory } from "react-router-dom"
import {
  FormHistory,
  LearningStandard,
  StandardObservation,
} from "../../../../global"
import { H3, P } from "../../base/styles.css"
import Title from "../title"
import FourPillars from "../fourPillars"
import Standards from "../standards"
import { FourPillars as FourPillarsEnum } from "../../../enums"
import { formatDistanceToNow } from "date-fns"

interface Props {
  form: FormHistory
  curriculum: LearningStandard[][]
  studentName: string
}

const HelpDesk = ({ form, curriculum, studentName }: Props) => {
  const { headers, rows, form_name, module_name, form_number } = form
  const history = useHistory()

  if (!rows || !rows[0] || !headers) history.push("/cohorts")
  if (!rows || !rows[0] || !headers) throw new TypeError("Headers is undefined")
  const submission = (rows as string[][])[0] as string[]
  const formTitle = `${form_name} ${module_name} ${form_number}`

  const note = submission[
    headers.findIndex((h) => h === "Additional Notes") as number
  ] as string

  const author = `Author: ${(
    submission[
      headers.findIndex((h) => h === "Email Address") as number
    ] as string
  )
    .split(".")
    .slice(0, 2)
    .map((word, idx) =>
      idx === 1
        ? word.split("@")[0][0].toUpperCase() + word.split("@")[0].slice(1)
        : word[0].toUpperCase() + word.slice(1)
    )
    .join(" ")}`

  const timestamp = `${
    submission[headers.findIndex((h) => h === "Timestamp") as number] as string
  } (${formatDistanceToNow(
    new Date(
      submission[
        headers.findIndex((h) => h === "Timestamp") as number
      ] as string
    ),
    { addSuffix: true }
  )})`

  const studentOne = {} as any
  const studentTwo = {} as any

  studentOne.name = submission[
    headers?.findIndex((h) => h === "Student One") as number
  ] as string

  studentTwo.name = submission[
    headers?.findIndex((h) => h === "Student Two") as number
  ] as string

  const isStudentOne = studentName === studentOne.name
  const isStudentTwo = studentName === studentTwo.name

  if (!isStudentOne && !isStudentTwo)
    throw new Error(
      `[ERROR] Help Desk. [${studentName}] does not match [${studentOne}] or [${studentTwo}]`
    )

  const technicalAbilityIdx = headers.findIndex(
    (h) => h === FourPillarsEnum.TECHNICAL_ABILIY
  )
  const rapportIdx = headers.findIndex((h) => h === FourPillarsEnum.RAPPORT)

  const accountabilityIdx = headers.findIndex(
    (h) => h === FourPillarsEnum.ACCOUNTABILITY
  )
  const gritIdx = headers.findIndex((h) => h === FourPillarsEnum.GRIT)

  if (
    [technicalAbilityIdx, rapportIdx, accountabilityIdx, gritIdx].some(
      (idx) => idx < 0
    )
  )
    throw new Error(
      `Could not locate Four Pillars from ${formTitle}, headers [${headers}], rows [${rows}]`
    )

  studentOne.technical =
    parseInt(submission[technicalAbilityIdx] as string) || undefined

  studentOne.rapport = parseInt(submission[rapportIdx] as string) || undefined

  studentOne.accountability =
    parseInt(submission[accountabilityIdx] as string) || undefined

  studentOne.grit = parseInt(submission[gritIdx] as string) || undefined

  studentTwo.technical =
    parseInt(
      submission[
        headers.indexOf(
          FourPillarsEnum.TECHNICAL_ABILIY,
          technicalAbilityIdx + 1
        ) as number
      ] as string
    ) || undefined

  studentTwo.rapport =
    parseInt(
      submission[
        headers.indexOf(FourPillarsEnum.RAPPORT, rapportIdx + 1) as number
      ] as string
    ) || undefined

  studentTwo.accountability =
    parseInt(
      submission[
        headers.indexOf(
          FourPillarsEnum.ACCOUNTABILITY,
          accountabilityIdx + 1
        ) as number
      ] as string
    ) || undefined

  studentTwo.grit =
    parseInt(
      submission[
        headers.indexOf(FourPillarsEnum.GRIT, gritIdx + 1) as number
      ] as string
    ) || undefined

  const moduleStandards = curriculum.find(
    (module) => (module[0] as LearningStandard).module_name === module_name
  ) as LearningStandard[]

  studentOne.standardObservations = [] as StandardObservation[]
  studentTwo.standardObservations = [] as StandardObservation[]

  headers.forEach((question, idx) => {
    const modStd = moduleStandards.find(
      (modStd) => modStd.standard === question
    )
    const observation = submission[idx]
    const isForStudentTwo = headers.indexOf(question) < idx
    if (modStd && observation && !isForStudentTwo) {
      studentOne.standardObservations.push({ ...modStd, observation })
    }
    if (modStd && observation && isForStudentTwo) {
      studentTwo.standardObservations.push({ ...modStd, observation })
    }
  })

  return (
    <Container>
      <Title title={formTitle} author={author} timestamp={timestamp} />
      <Note>
        <P>
          <Chip>Notes</Chip>
          {note}
        </P>
      </Note>
      <div>
        <StudentContainer>
          <H3>{studentOne.name}</H3>
          <FourPillars
            T={studentOne.technical}
            R={studentOne.rapport}
            A={studentOne.accountability}
            G={studentOne.grit}
          />
          <Standards
            standards={studentOne.standardObservations}
            compare={true}
          />
        </StudentContainer>
        <StudentContainer>
          <H3>{studentTwo.name}</H3>
          <FourPillars
            T={studentTwo.technical}
            R={studentTwo.rapport}
            A={studentTwo.accountability}
            G={studentTwo.grit}
          />
          <Standards
            standards={studentTwo.standardObservations}
            compare={true}
          />
        </StudentContainer>
      </div>
    </Container>
  )
}

export default HelpDesk
