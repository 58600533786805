import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { FixMe, ReduxStatus, WhiteboardPrompt } from "../../../global"
import { whiteboardPrompts } from "../../services/api"

interface InitialWhiteboardPromptsState {
  status: ReduxStatus
  msg: string | null
  data: WhiteboardPrompt[]
}

const initialState: InitialWhiteboardPromptsState = {
  status: "pending",
  msg: null,
  data: [],
}

export const fetchWhiteboardPrompts = createAsyncThunk(
  "whiteboarding/fetch",
  async () => {
    return await whiteboardPrompts
      .fetch()
      .catch(({ message }) =>
        Promise.reject("Could not retrieve Whiteboarding Prompts. " + message)
      )
  }
)

const whiteboardPromptsSlice = createSlice({
  name: "whiteboarding",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchWhiteboardPrompts.fulfilled,
      (state, action: { payload: FixMe }) => {
        state.status = "ready"
        state.data = action.payload
        state.msg = null
      }
    )
    builder.addCase(fetchWhiteboardPrompts.pending, (state) => {
      state.status = "pending"
    })
    builder.addCase(fetchWhiteboardPrompts.rejected, (state, action) => {
      state.status = "error"
      state.msg = action.error.message + ""
    })
  },
})

export default whiteboardPromptsSlice.reducer
