import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"

import { connect, ConnectedProps } from "react-redux"
import { setUserError } from "../../features/userProfile/userProfileSlice"

import { H0, Spinner } from "./styles.css"
import styled from "styled-components"

import { ReduxMsg, ReduxStatus, User } from "../../../global"

const LoadingContainer = styled.div`
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > h1,
  div {
    color: ${({ theme }) => theme.color.text.normal};
  }
`

const mapState = ({
  user_profile: userProfile,
}: {
  user_profile: { status: ReduxStatus; msg: ReduxMsg; data: User }
}) => ({
  userProfile,
})

const mapDispatch = { setUserError }
const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux

const Loading = ({ setUserError }: Props) => {
  const [loadDelayCount, setLoadDelayCount] = useState(0)
  const history = useHistory()
  useEffect(() => {
    if (loadDelayCount === 30) {
      const maxDelay = setTimeout(() => {
        setUserError(
          `Its not you, its us. If you refresh the page, it should be available for you now.
           This only happens when the application is not in general use.`
        )
        history.push("/error")
      }, 45000)
      return () => clearTimeout(maxDelay)
    } else {
      const delay = setTimeout(
        () => setLoadDelayCount(loadDelayCount + 1),
        1000
      )
      return () => clearTimeout(delay)
    }
  }, [loadDelayCount])

  return (
    <LoadingContainer>
      {loadDelayCount < 7 && <H0>Loading...</H0>}
      {loadDelayCount > 6 && loadDelayCount < 14 && <H0>Loading...</H0>}
      {loadDelayCount > 13 && <H0>AppScript can take a while...</H0>}
      <Spinner style={{ width: "100px", height: "100px" }} />
    </LoadingContainer>
  )
}

export default connector(Loading)
