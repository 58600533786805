import React from "react"
import { useHistory } from "react-router-dom"
import { Container, ItemDetail } from "./styles.css"

type FixMe = any

const ListItem = (props: FixMe) => {
  const {
    itemDetails,
    itemDataHandler,
    itemUrlVar,
    columnWidths,
    itemTextCenter = false,
    itemUrlHandler,
    itemData,
    itemAction,
  } = props

  const itemDetailKeys = Object.keys(itemDetails)
  const { name } = itemDetails
  const history = useHistory()

  const navigateToItemPage = (url: string) => {
    history.push(url)
  }

  const handleItemClick = () => {
    if (itemAction) {
    } else if (itemUrlHandler) {
      const urlVariable = itemDetails[itemUrlVar]
      navigateToItemPage(itemUrlHandler(urlVariable))
    }
  }

  return (
    <Container>
      {itemDetailKeys
        .map((itemKey: string) => itemDetails[itemKey])
        .map((item: string, idx: number) => {
          return (
            <ItemDetail
              style={
                itemTextCenter[idx]
                  ? { width: columnWidths[idx], justifyContent: "center" }
                  : { width: columnWidths[idx], justifyContent: "left" }
              }
              key={`${name}-${idx}`}
              onClick={handleItemClick}
            >
              {item}
            </ItemDetail>
          )
        })}
    </Container>
  )
}

export default ListItem
