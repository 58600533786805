import React from "react"
import {
  Container,
  Title,
  StandardContainer,
  Level,
  LevelContainer,
} from "./styles.css"
import { useHistory } from "react-router-dom"
import { StandardObservation } from "../../../../global"
import { H4, P } from "../../base/styles.css"
import { GateLevel, StandardLevel } from "../../../enums"
import styled from "styled-components"
import { colorGradient } from "../../../utils"

const ToolP = styled(H4)`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  & span {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    font-size: ${({ theme }) => theme.font.size.s};
  }
  &:hover span {
    visibility: visible;
  }
`

const Standards = ({
  standards,
}: {
  standards: StandardObservation[]
  compare?: boolean
}) => {
  const history = useHistory()

  const gating = (standards || []).filter(
    ({ gate_level }) => gate_level === GateLevel.GATING
  )
  const gatingEventual = (standards || []).filter(
    ({ gate_level }) => gate_level === GateLevel.GATING_EVENTUAL
  )
  const nonGating = (standards || []).filter(
    ({ gate_level }) => gate_level === GateLevel.NON_GATING
  )

  const format = (name: string) =>
    name
      .toLowerCase()
      .split("_")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ")

  const colors = {
    [StandardLevel.DEMONSTRATED_UNDERSTANDING]: colorGradient(1),
    [StandardLevel.IMPLIED_UNDERSTANDING]: colorGradient(0.8),
    [StandardLevel.IMPLIED_MISUNDERSTANDING]: colorGradient(0.3),
    [StandardLevel.DEMONSTRATED_MISUNDERSTANDING]: colorGradient(0),
  }

  return (
    <Container>
      {[
        [GateLevel.GATING, gating],
        [GateLevel.GATING_EVENTUAL, gatingEventual],
        [GateLevel.NON_GATING, nonGating],
      ].map(([gateLevel, observations], idx) =>
        observations.length > 0 ? (
          <div key={`sandard-level-${idx}`}>
            <Title>{format(gateLevel as GateLevel)} Standard</Title>
            <div>
              {(observations as StandardObservation[]).map((observation, idy) =>
                observation.observation ? (
                  <StandardContainer key={`observation-${idy}`}>
                    {Object.values(StandardLevel).map((standardLevel, idz) => (
                      <LevelContainer key={`level-${idz}`}>
                        <Level
                          style={
                            standardLevel === observation.observation
                              ? {
                                  backgroundColor: colors[
                                    observation.observation
                                  ] as string,
                                }
                              : {}
                          }
                        >
                          <ToolP>
                            {" "}
                            <span>{standardLevel}</span>
                          </ToolP>
                        </Level>
                      </LevelContainer>
                    ))}
                    <P>{observation.standard}</P>
                  </StandardContainer>
                ) : null
              )}
            </div>
          </div>
        ) : null
      )}
    </Container>
  )
}

export default Standards
