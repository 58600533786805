import styled from "styled-components"
import { Button, UL, LI } from "../base/styles.css"

export const Container = styled.div`
  margin: 20px auto;
  position: -webkit-sticky;
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  position: sticky;
  top: 0;
  z-index: 5;
  > ul {
    margin: 0 20px;
  }
  > ul > li {
    margin: 0;
    line-height: 1.5;
    font-family: ${({ theme }) => theme.font.family.lato};
    font-weight: ${({ theme }) => theme.font.weight.light};
    display: table;
    &:after {
      display: block;
      content: "";
      border-bottom: solid 1px ${({ theme }) => theme.color.text.light};
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }
    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
    &:after {
      transform-origin: 0% 50%;
    }
    &.active:after {
      transform: scaleX(1);
    }
  }
`

export const NavIconContainer = styled.div`
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 7px -4px ${({ theme }) => theme.color.text.normal};
    transition: box-shadow 0.3s ease-in-out;
  }
  width: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.backgroundColor.primary};
  border-radius: 30px;
  padding: 6px;
  box-shadow: ${({ theme }) => theme.boxShadow};
`

export const Spacer = styled.div`
  height: 50px;
`

export const ThemeButton = styled(Button)`
  background-color: ${({ theme }) => theme.backgroundColor.navbar};
  border: none;
  color: ${({ theme }) => theme.color.text.normal};
  outline: none;
  &:focus {
    outline: none;
  }
`
export const MenuItems = styled(UL)`
  margin: 0;
`

export const MenuItem = styled(LI)`
  list-style: none;
  margin: 10px 0;
  padding: 20px 0;
  &:first-child {
    margin-top: 50px;
  }
  font-size: ${({ theme }) => theme.font.size.ml};
  &:hover {
    cursor: pointer;
  }
`
