import styled from "styled-components"
import { H4, P } from "../../base/styles.css"

export const Container = styled.div`
  width: 100%;
  margin-bottom: 80px;
`
export const Note = styled.div`
  margin-bottom: 15px;
`

export const Chip = styled.span`
  border: 1px solid ${({ theme }) => theme.borderColor};
  padding: 2px 10px;
  margin-right: 10px;
  border-radius: 20px;
  font-family: ${({ theme }) => theme.font.family.lora};
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-style: ${({ theme }) => theme.font.style.normal};
  color: ${({ theme }) => theme.color.text.normal};
  font-size: ${({ theme }) => theme.font.size.m};
`

export const ExperienceContainer = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
`

export const Answer = styled(P)``

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  > div {
    width: 530px;
    display: flex;
    flex-direction: row;
    align-items: center;
    > h3 {
      width: 250px;
    }
    p:nth-child(1) {
      margin-right: 20px;
      width: 250px;
    }
  }
`

export const Level = styled.div`
  height: 20px;
  width: 20px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 20px;
  margin: auto;
`
export const LevelName = styled(H4)`
  word-break: word;
  text-align: center;
`
export const LevelContainer = styled.div`
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  justify-content: center;
  h4 {
    color: white;
  }
  h4:hover {
    position: relative;
  }
`

export const ToolP = styled(H4)`
  position: relative;
  display: inline-block;
  width: 100px;
  height: 30px;
  & span {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    font-size: ${({ theme }) => theme.font.size.s};
  }
  &:hover span {
    visibility: visible;
  }
`
