import React, { useEffect, useState } from "react"

import { connect, ConnectedProps } from "react-redux"
import { useSnackBar } from "../../../hooks/useSnackbar"

import {
  Container,
  ModalContainer,
  Title,
  Steps,
  Step,
  StepTitle,
  StepNumber,
  FormsContainer,
  StudentSubmission,
  AvailableFormsContainer,
  OutputLinksContainer,
} from "./styles.css"
import {
  Button,
  H0,
  H3,
  Label,
  LabelDescription,
  P,
  Select,
} from "../../../components/base/styles.css"

import PageHeader from "../../../components/pageHeader"
import Loading from "../../../components/base/loading"

import Modal from "@material-ui/core/Modal"

import { fetchStudents, updateStudentForms } from "./students/studentSlice"
import {
  createStaffNote,
  createPairingObservation,
  createSprintWrapUp,
  createHelpDesk,
  createWhiteboardReflection,
} from "../formsSlice"

import {
  Cohort as ICohort,
  CreateFormResponsePayload,
  Form,
  FormHistory,
  LearningStandard,
  ReduxMsg,
  ReduxStatus,
  Student as IStudent,
  User,
  WhiteboardPrompt,
} from "../../../../global"
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router"
import Student from "./students/student"
import Students from "./students"
import { FormTemplate } from "../../../enums"

type FixMe = any

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux

interface HeaderAction {
  name: string
  handler: () => void
}

const mapState = ({
  students,
  user_profile: userProfile,
  curriculum,
  cohorts,
  whiteboard_prompts: whiteboardPrompts,
}: {
  cohorts: { status: ReduxStatus; data: ICohort[]; msg: ReduxMsg }
  students: { status: ReduxStatus; data: IStudent[]; msg: ReduxMsg }
  curriculum: { status: ReduxStatus; data: LearningStandard[][]; msg: ReduxMsg }
  whiteboard_prompts: {
    status: ReduxStatus
    data: WhiteboardPrompt[]
    msg: ReduxMsg
  }
  user_profile: { status: ReduxStatus; data: User; msg: ReduxMsg }
}) => ({ students, userProfile, curriculum, cohorts, whiteboardPrompts })
const connector = connect(mapState, {
  fetchStudents,
  createStaffNote,
  createHelpDesk,
  createPairingObservation,
  createSprintWrapUp,
  createWhiteboardReflection,
  updateStudentForms,
})

const Cohort = ({
  students: { status: students_status, msg: students_msg, data: students },
  cohorts: { status: cohorts_status, msg: cohorts_msg, data: cohorts },
  userProfile: {
    status: userProfile_status,
    msg: userProfile_msg,
    data: userProfile,
  },
  curriculum: {
    status: curriculum_status,
    msg: curriculum_msg,
    data: curriculum,
  },
  whiteboardPrompts: {
    status: whiteboardPrompts_status,
    msg: whiteboardPrompts_msg,
    data: whiteboardPrompts,
  },
  fetchStudents,
  createStaffNote,
  createHelpDesk,
  createPairingObservation,
  createSprintWrapUp,
  createWhiteboardReflection,
  updateStudentForms,
}: Props) => {
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const { emitSnackBarAlert } = useSnackBar()
  const [open, setOpen] = useState(false)
  const [selectedCohort, selectCohort] = useState<string>()
  const [campus, setCampus] = useState<number>(0)
  const [selectedForm, selectForm] = useState<FormTemplate>()
  const [selectedModule, selectModule] = useState<string>("Select a module")
  const [isProcessing, setIsProcessing] = useState(false)
  const [isInit, setIsInit] = useState(false)
  const [isComplete, setIsComplete] = useState<CreateFormResponsePayload>()
  const [showSprintWrapUps, setShowSprintWrapUps] = useState(false)
  const [showWhiteboardReflections, setShowWhiteboardReflections] =
    useState(false)

  const handleOpen = (cohort: string) => {
    selectCohort(cohort)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setShowSprintWrapUps(false)
    setShowWhiteboardReflections(false)
    setIsComplete(undefined)
  }

  const pathname = location.pathname.split("/")
  const pn = pathname

  const cohortName = pathname[2].toUpperCase()
  const cohort =
    (cohorts.find(({ name }) => name === cohortName) as ICohort) || {}
  const cohortSprintWrapUpForms = (cohort.form_history || []).filter(
    ({ form_name }) => form_name === "Sprint Wrap Up"
  )
  const cohortWhiteboardReflectionForms = (cohort.form_history || []).filter(
    ({ form_name }) => form_name === "Whiteboarding Reflection"
  )

  useEffect(() => {
    const loadStudents = async () => {
      setIsInit(true)
      const campus = userProfile.campuses.findIndex((campus) =>
        pathname[2].includes(campus)
      )
      const campusData = userProfile.campus_data_ids[campus]
      const { payload: students } = (await fetchStudents({
        cohort_name: cohortName,
        campus_data_id: campusData,
      })) as { payload: IStudent[] }
      setCampus(campus)
      await updateStudentForms({ cohorts, cohort_name: cohortName, students })
    }

    if (userProfile_status !== "ready") history.push("/")
    if (!isInit && cohorts_status === "ready") loadStudents()
  }, [students_status, userProfile_status, cohorts_status])

  const copyToClipBoard = (text: string) => {
    // const copyText = `https://docs.google.com/forms/d/e/${props.formDetails.publish_id}/viewform`
    if (window.isSecureContext) {
      navigator.clipboard.writeText(text).then(function () {
        emitSnackBarAlert(`Copied! (${text})`)
      })
    } else {
      emitSnackBarAlert(`Could not copy! (${text})`)
    }
  }

  const handleCreateForm = async () => {
    const formToCreate = userProfile.campus_forms.find(
      ({ form_id }) => form_id === selectedForm
    ) as Form
    const curriculumQuestions = curriculum.find(
      (module) => module[0].module_name === selectedModule
    ) as LearningStandard[]

    setIsProcessing(true)

    switch (selectedForm) {
      case FormTemplate.STAFF_NOTES:
        await createStaffNote({
          formId: FormTemplate.STAFF_NOTES,
          formHistoryId: cohort.form_history_id,
          formTemplateId: formToCreate.form_template_id,
          destinationFolderId: cohort.form_bin_id,
          formName: formToCreate.form_name,
          curriculumQuestions,
          moduleName: selectedModule,
          cohortName: cohortName,
          students: students.map(({ name }) => name),
          userEmail: userProfile.email,
          userEmailGroup: userProfile.email_groups[campus],
        }).then((data: any) => {
          setIsProcessing(false)
          setIsComplete(data.payload)
        })
        break
      case FormTemplate.PAIRING_OBSERVATION:
        await createPairingObservation({
          formId: FormTemplate.PAIRING_OBSERVATION,
          formHistoryId: cohort.form_history_id,
          formTemplateId: formToCreate.form_template_id,
          destinationFolderId: cohort.form_bin_id,
          formName: formToCreate.form_name,
          curriculumQuestions,
          moduleName: selectedModule,
          cohortName: cohortName,
          students: students.map(({ name }) => name),
          userEmail: userProfile.email,
          userEmailGroup: userProfile.email_groups[campus],
        }).then((data: any) => {
          setIsProcessing(false)
          setIsComplete(data.payload)
        })
        break
      case FormTemplate.HELP_DESK:
        await createHelpDesk({
          formId: FormTemplate.HELP_DESK,
          formHistoryId: cohort.form_history_id,
          formTemplateId: formToCreate.form_template_id,
          destinationFolderId: cohort.form_bin_id,
          formName: formToCreate.form_name,
          curriculumQuestions,
          moduleName: selectedModule,
          cohortName: cohortName,
          students: students.map(({ name }) => name),
          userEmail: userProfile.email,
          userEmailGroup: userProfile.email_groups[campus],
        }).then((data: any) => {
          setIsProcessing(false)
          setIsComplete(data.payload)
        })
        break
      case FormTemplate.SPRINT_WRAP_UP:
        await createSprintWrapUp({
          formId: FormTemplate.SPRINT_WRAP_UP,
          formHistoryId: cohort.form_history_id,
          formTemplateId: formToCreate.form_template_id,
          destinationFolderId: cohort.form_bin_id,
          formName: formToCreate.form_name,
          curriculumQuestions: [],
          moduleName: selectedModule,
          cohortName: cohortName,
          students: students.map(({ name }) => name),
          userEmail: userProfile.email,
          userEmailGroup: userProfile.email_groups[campus],
        }).then((data: any) => {
          setIsProcessing(false)
          setIsComplete(data.payload)
        })
        break
      case FormTemplate.WHITEBOARD:
        await createWhiteboardReflection({
          formId: FormTemplate.WHITEBOARD,
          formHistoryId: cohort.form_history_id,
          formTemplateId: formToCreate.form_template_id,
          destinationFolderId: cohort.form_bin_id,
          formName: formToCreate.form_name,
          curriculumQuestions: [],
          moduleName: selectedModule,
          cohortName: cohortName,
          students: students.map(({ name }) => name),
          userEmail: userProfile.email,
          userEmailGroup: userProfile.email_groups[campus],
        }).then((data: any) => {
          setIsProcessing(false)
          setIsComplete(data.payload)
        })
        break
    }
  }

  const format = (name: string) =>
    name
      .toLowerCase()
      .split("_")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ")

  const handleOpenCreateFormDialogue = () => {
    handleOpen(pathname[pn.length - 1].toUpperCase())
  }

  return (
    <Switch>
      <Route path={`${match.path}/:student`}>
        <Student />
      </Route>
      <Route>
        <Container>
          {cohort && (
            <PageHeader
              title={cohortName}
              subtitle={`${(students || []).length} students`}
              pageActions={[
                {
                  name: "Create Form",
                  handler: handleOpenCreateFormDialogue,
                },
                {
                  name: "Sprint Wrap Ups",
                  handler: () => {
                    setShowSprintWrapUps(true)
                    handleOpen(pathname[pn.length - 1].toUpperCase())
                  },
                },
                {
                  name: "Whiteboard\nReflections",
                  handler: () => {
                    setShowWhiteboardReflections(true)
                    handleOpen(pathname[pn.length - 1].toUpperCase())
                  },
                },

                {
                  component: (
                    <AvailableFormsContainer>
                      <Select
                        onChange={({ target: { value: publish_id } }) =>
                          publish_id && publish_id.length > 0
                            ? window.open(
                                `https://docs.google.com/forms/d/e/${publish_id}/viewform?usp=sf_link`,
                                "_blank"
                              )
                            : null
                        }
                      >
                        {[
                          {
                            form_name: "Staff Notes",
                            form_number: "",
                            module_name: "Staff Notes",
                            publish_id: "",
                          },
                          ...((cohort?.form_history || []) as FormHistory[]),
                        ]
                          ?.filter(
                            ({ form_name }) => form_name === "Staff Notes"
                          )
                          .map(
                            (
                              {
                                form_name,
                                form_number,
                                module_name,
                                publish_id,
                              },
                              idx
                            ) =>
                              idx < 1 ? (
                                <option
                                  value={publish_id}
                                  key={`${module_name}`}
                                >
                                  {`${module_name}`}
                                </option>
                              ) : (
                                <option
                                  value={publish_id}
                                  key={`${form_name} ${module_name} ${form_number}`}
                                >
                                  {`${form_name} ${module_name} ${form_number}`}
                                </option>
                              )
                          )}
                      </Select>
                      <Select
                        onChange={({ target: { value: publish_id } }) =>
                          publish_id && publish_id.length > 0
                            ? window.open(
                                `https://docs.google.com/forms/d/e/${publish_id}/viewform?usp=sf_link`,
                                "_blank"
                              )
                            : null
                        }
                      >
                        {[
                          {
                            form_name: "Pairing Observation",
                            form_number: "",
                            module_name: "Pairing Obs.",
                            publish_id: "",
                          },
                          ...((cohort?.form_history || []) as FormHistory[]),
                        ]
                          ?.filter(
                            ({ form_name }) =>
                              form_name === "Pairing Observation"
                          )
                          .map(
                            (
                              {
                                form_name,
                                form_number,
                                module_name,
                                publish_id,
                              },
                              idx
                            ) =>
                              idx < 1 ? (
                                <option
                                  value={publish_id}
                                  key={`${module_name}`}
                                >
                                  {`${module_name}`}
                                </option>
                              ) : (
                                <option
                                  value={publish_id}
                                  key={`${form_name} ${module_name} ${form_number}`}
                                >
                                  {`${form_name} ${module_name} ${form_number}`}
                                </option>
                              )
                          )}
                      </Select>
                      <Select
                        onChange={({ target: { value: publish_id } }) =>
                          publish_id && publish_id.length > 0
                            ? window.open(
                                `https://docs.google.com/forms/d/e/${publish_id}/viewform?usp=sf_link`,
                                "_blank"
                              )
                            : null
                        }
                      >
                        {[
                          {
                            form_name: "Help Desk",
                            form_number: "",
                            module_name: "Help Desk",
                            publish_id: "",
                          },
                          ...((cohort?.form_history || []) as FormHistory[]),
                        ]
                          ?.filter(({ form_name }) => form_name === "Help Desk")
                          .map(
                            (
                              {
                                form_name,
                                form_number,
                                module_name,
                                publish_id,
                              },
                              idx
                            ) =>
                              idx < 1 ? (
                                <option
                                  value={publish_id}
                                  key={`${module_name}`}
                                >
                                  {`${module_name}`}
                                </option>
                              ) : (
                                <option
                                  value={publish_id}
                                  key={`${form_name} ${module_name} ${form_number}`}
                                >
                                  {`${form_name} ${module_name} ${form_number}`}
                                </option>
                              )
                          )}
                      </Select>
                    </AvailableFormsContainer>
                  ),
                  name: "Forms",
                  handler: () => alert("The above component should be read"),
                },
              ]}
            />
          )}
          <Students />

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {isProcessing ? (
              <ModalContainer>
                <Loading />
              </ModalContainer>
            ) : showSprintWrapUps ? (
              <ModalContainer>
                <Title>
                  <H0>{selectedCohort} Sprint Wrap Up Forms </H0>
                </Title>
                <FormsContainer>
                  {(cohortSprintWrapUpForms || []).map(
                    ({ form_name, form_number, module_name, publish_id }) => {
                      return (
                        <div
                          key={`form-${form_name}-${module_name}-${form_number}`}
                        >
                          <H3
                            onClick={() =>
                              copyToClipBoard(
                                `[${cohortName} Sprint Wrap Up [${module_name}]](https://docs.google.com/forms/d/e/${publish_id}/viewform?usp=sf_link)`
                              )
                            }
                          >{`${form_name} ${module_name} ${form_number}   📋`}</H3>
                          {students.map((student) => (
                            <StudentSubmission
                              key={`${student.name}-${form_name}-${module_name}-${form_number}`}
                            >
                              <LabelDescription>
                                {student.name}
                              </LabelDescription>
                              <div>
                                {(student.form_submissions || []).some(
                                  ({
                                    form_name: fn,
                                    module_name: mn,
                                    form_number: fnum,
                                    rows,
                                  }) =>
                                    form_name === fn &&
                                    module_name === mn &&
                                    form_number === fnum &&
                                    rows &&
                                    rows.some(
                                      ([, name]) => name === student.name
                                    )
                                )
                                  ? "✅"
                                  : "⛔️"}
                              </div>
                            </StudentSubmission>
                          ))}
                        </div>
                      )
                    }
                  )}
                </FormsContainer>
              </ModalContainer>
            ) : showWhiteboardReflections ? (
              <ModalContainer>
                <Title>
                  <H0>{selectedCohort} Whiteboard Reflection Forms </H0>
                </Title>
                <FormsContainer>
                  {(cohortWhiteboardReflectionForms || []).map(
                    ({ form_name, form_number, module_name, publish_id }) => {
                      return (
                        <div
                          key={`form-${form_name}-${module_name}-${form_number}`}
                        >
                          <H3
                            onClick={() =>
                              copyToClipBoard(
                                `*${cohortName} Whiteboarding [${module_name}]*:\n
[Reflection Form](https://docs.google.com/forms/d/e/${publish_id}/viewform?usp=sf_link)

[Prompt](${
                                  (
                                    whiteboardPrompts.find(
                                      ({ name }) => name === module_name
                                    ) || {
                                      prompt_url: "undefined",
                                    }
                                  ).prompt_url
                                })

[Solution](${
                                  (
                                    whiteboardPrompts.find(
                                      ({ name }) => name === module_name
                                    ) || {
                                      solution_url: "undefined",
                                    }
                                  ).solution_url
                                })`
                              )
                            }
                          >{`${form_name} ${module_name} ${form_number}   📋`}</H3>
                          {students.map((student) => (
                            <StudentSubmission
                              key={`${student.name}-${form_name}-${module_name}-${form_number}`}
                            >
                              <LabelDescription>
                                {student.name}
                              </LabelDescription>
                              <div>
                                {(student.form_submissions || []).some(
                                  ({
                                    form_name: fn,
                                    module_name: mn,
                                    form_number: fnum,
                                    rows,
                                  }) =>
                                    form_name === fn &&
                                    module_name === mn &&
                                    form_number === fnum &&
                                    rows &&
                                    rows.some(
                                      ([, name]) => name === student.name
                                    )
                                )
                                  ? "✅"
                                  : "⛔️"}
                              </div>
                            </StudentSubmission>
                          ))}
                        </div>
                      )
                    }
                  )}
                </FormsContainer>
              </ModalContainer>
            ) : isComplete ? (
              <ModalContainer>
                <Title>
                  <H0>Your form has been generated</H0>
                </Title>
                <OutputLinksContainer>
                  <P>{`
${cohortName} ${format(selectedForm || "")} [${selectedModule}]
                  `}</P>
                  <P>{`
https://docs.google.com/forms/d/e/${isComplete.publish_id}/viewform?usp=sf_link
                  `}</P>
                  <Button
                    onClick={() =>
                      copyToClipBoard(
                        `[${cohortName} ${format(
                          selectedForm || ""
                        )} [${selectedModule}]](https://docs.google.com/forms/d/e/${
                          isComplete.publish_id
                        }/viewform?usp=sf_link)`
                      )
                    }
                  >
                    {"Copy Form URL"}
                  </Button>
                  {selectedForm === FormTemplate.WHITEBOARD && (
                    <Button
                      onClick={() =>
                        copyToClipBoard(
                          (
                            whiteboardPrompts.find(
                              ({ name }) => name === selectedModule
                            ) || { prompt_url: "undefined" }
                          ).prompt_url
                        )
                      }
                    >{`Copy Prompt URL`}</Button>
                  )}
                  {selectedForm === FormTemplate.WHITEBOARD && (
                    <Button
                      onClick={() =>
                        copyToClipBoard(
                          (
                            whiteboardPrompts.find(
                              ({ name }) => name === selectedModule
                            ) || { solution_url: "undefined" }
                          ).solution_url
                        )
                      }
                    >{`Copy Solution URL`}</Button>
                  )}
                  {selectedForm === FormTemplate.WHITEBOARD && (
                    <Button
                      onClick={() =>
                        copyToClipBoard(
                          `${cohortName} Whiteboarding [${selectedModule}]:\n
[Reflection Form](https://docs.google.com/forms/d/e/${
                            isComplete.publish_id
                          }/viewform?usp=sf_link)
[Prompt](${
                            (
                              whiteboardPrompts.find(
                                ({ name }) => name === selectedModule
                              ) || { prompt_url: "undefined" }
                            ).prompt_url
                          })
[Solution](${
                            (
                              whiteboardPrompts.find(
                                ({ name }) => name === selectedModule
                              ) || { solution_url: "undefined" }
                            ).solution_url
                          })`
                        )
                      }
                    >{`Copy For Slack`}</Button>
                  )}
                </OutputLinksContainer>
              </ModalContainer>
            ) : (
              <ModalContainer>
                <Title>
                  <H0>{`Create a form for ${selectedCohort}`}</H0>
                </Title>
                <Steps>
                  <Step>
                    <div>
                      <StepNumber>1</StepNumber>
                      <StepTitle>Select a form</StepTitle>
                    </div>
                    <Button onClick={() => selectForm(FormTemplate.HELP_DESK)}>
                      Help Desk
                    </Button>
                    <Button
                      onClick={() => selectForm(FormTemplate.STAFF_NOTES)}
                    >
                      Staff Notes
                    </Button>
                    <Button
                      onClick={() =>
                        selectForm(FormTemplate.PAIRING_OBSERVATION)
                      }
                    >
                      Pairing Observation
                    </Button>
                    <Button
                      onClick={() => selectForm(FormTemplate.SPRINT_WRAP_UP)}
                    >
                      Sprint Wrap Up
                    </Button>
                    <Button onClick={() => selectForm(FormTemplate.WHITEBOARD)}>
                      Whiteboard Reflection
                    </Button>
                  </Step>
                  {!isComplete && selectedForm && (
                    <Step>
                      <div>
                        <StepNumber>2</StepNumber>
                        <StepTitle>
                          Select Options for{" "}
                          {selectedForm
                            .toLowerCase()
                            .split("_")
                            .map(
                              (word) =>
                                `${word[0].slice().toUpperCase()}${word.slice(
                                  1
                                )}`
                            )
                            .join(" ")}
                        </StepTitle>
                      </div>
                      {selectedForm === FormTemplate.WHITEBOARD ? (
                        <Select
                          value={selectedModule}
                          onChange={({ target: { value } }) =>
                            selectModule(value as string)
                          }
                        >
                          {[
                            {
                              name: "Select a problem",
                              id: "select_a_problem",
                            },
                            ...whiteboardPrompts,
                          ].map(({ name, id }) => (
                            <option key={id} value={name}>
                              {name}
                            </option>
                          ))}
                        </Select>
                      ) : (
                        <Select
                          value={selectedModule}
                          onChange={({ target: { value } }) =>
                            selectModule(value as string)
                          }
                        >
                          {[
                            [
                              {
                                module_name: "Select a module",
                                id: "select_a_module",
                              },
                            ],
                            ...curriculum,
                          ].map(([{ module_name, id }]) => (
                            <option key={id} value={module_name}>
                              {module_name}
                            </option>
                          ))}
                        </Select>
                      )}
                    </Step>
                  )}
                  {!isComplete && selectedModule !== "Select a module" && (
                    <Step>
                      <div>
                        <StepNumber>3</StepNumber>
                        <StepTitle>Confirm Selection</StepTitle>
                      </div>
                      <Button onClick={handleCreateForm}>Create Form</Button>
                    </Step>
                  )}
                </Steps>
              </ModalContainer>
            )}
          </Modal>
        </Container>
      </Route>
    </Switch>
  )
}

export default connector(Cohort)
