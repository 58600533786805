import styled from "styled-components"
import { Field, Form } from "formik"
/*
   NOTE: Login does not receive props from Theme Provider because it is outside of the App
*/

export const Container = styled.div`
  background-color: #f7fafc;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`

export const LoginContent = styled.div`
  width: 300px;
  padding: 80px;
  background-color: white;
  border: 1px solid #cacaca;
  border-radius: 7px;
  > div:last-child {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  > form {
    display: flex;
    flex-direction: column;
  }
`

export const Header = styled.h1`
  font-family: "Lato";
  font-size: 1.7rem;
  text-align: center;
  margin-bottom: 15px;
`

export const Slogan = styled.h2`
  font-family: "Lato";
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 20px;
`

export const Description = styled.p`
  font-family: "Lato";
  color: black;
  font-size: 1rem;
  margin: 5px 0;
  line-height: 1.2;
`

export const Paragraph = styled.p`
  font-family: "Lato";
  font-size: 0.8rem;
  margin: 5px 0 20px 0;
  padding: 0 20px;
  color: #696e6f;
`

export const FormErrorWhitespace = styled.div``
export const FormErrorDetails = styled.div`
  font-family: "Lato";
  font-size: 1rem;
  color: firebrick;
  margin: 5px 0;
  line-height: 1.2;
`

export const Label = styled.label`
  font-family: "Lato";
  color: #333333;
  font-size: 1rem;
  font-weight: normal;
  margin-top: 20px;
`

export const Submit = styled.button`
  font-family: "Lato";
  font-weight: normal;
  outline: none;
  font-size: 1rem;
  border: 1px solid #333333;
  border-radius: 7px;
  background-color: white;
  margin: 20px 0 10px 0;
  padding: 5px;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    background-color: #f7fafc;
  }
`

export const Sublink = styled.div`
  text-align: right;
  > a {
    color: navy;
    font-family: "Lato";
    font-size: 0.8rem;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`

export const FormikForm = styled(Form)`
  color: ${({ theme }) => theme.color};
`

export const FormikField = styled(Field)`
  padding: 5px;
  color: rgb(60, 66, 87);
  min-height: 1.5rem;
  font-size: 1rem;
  font-family: "Lato";
  font-weight: normal;
  line-height: 1.5;
`
