import { combineReducers } from "redux"
import userProfileReducer from "../features/userProfile/userProfileSlice"
import cohortsReducer from "../features/cohorts/cohortSlice"
import curriculumReducer from "../features/curriculum/curriculumSlice"
import whiteboardPromptsReducer from "../features/prompts/whiteboardPromptsSlice"
import studentsReducer from "../features/cohorts/cohort/students/studentSlice"

export default combineReducers({
  user_profile: userProfileReducer,
  cohorts: cohortsReducer,
  students: studentsReducer,
  curriculum: curriculumReducer,
  whiteboard_prompts: whiteboardPromptsReducer,
})
