import React, { useEffect, useState } from "react"
import { useSnackBar } from "../../../../hooks/useSnackbar"
import { useRouteMatch, Route, Switch, useHistory } from "react-router-dom"
import { differenceInWeeks, isSameWeek, startOfToday } from "date-fns"
import GitHubIcon from "@material-ui/icons/GitHub"
import { connect, ConnectedProps } from "react-redux"
import { fetchStudents } from "./studentSlice"

import { Container } from "./styles.css"
import { H3, H4, P } from "../../../../components/base/styles.css"

import {
  ReduxStatus,
  ReduxMsg,
  User,
  FixMe,
  Cohort as ICohort,
  Student as IStudent,
} from "../../../../../global"
import List from "../../../../components/list"
import { FormTemplate, FourPillars } from "../../../../enums"

import styled from "styled-components"
import { colorGradient, info } from "../../../../utils"

const TDCenter = styled.td`
  min-width: 75px;
  text-align: center;
`
const TDLeft = styled.td`
  min-width: 75px;
`
const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  h4 {
    color: white;
  }
  h4:hover {
    position: relative;
  }
`
const Clipboard = styled.div`
  width: 100%;
  margin: auto;
  font-size: ${({ theme }) => theme.font.size.s};
  text-align: center;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.color.text.light};
  }
`

const ToolP = styled(H4)`
  /* position: relative; */
  display: inline-block;
  & span {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    font-size: ${({ theme }) => theme.font.size.s};
  }
  &:hover span {
    visibility: visible;
  }
`

const tableRow = (title: string, avg: number, count: number) => {
  const backgroundColor = colorGradient((avg - 1) / (5 - 1))
  const style = avg > 0 ? { backgroundColor } : {}
  return (
    <tr>
      <TDLeft>
        <H4>{title}</H4>
      </TDLeft>
      <TDCenter>
        <Circle style={style}>
          <ToolP>
            {avg === 0 ? null : parseFloat(avg.toFixed(2))}
            <span>{`count: ${count}`}</span>
          </ToolP>
        </Circle>
      </TDCenter>
    </tr>
  )
}

const mapState = ({
  students,
  cohorts,
  user_profile: userProfile,
}: {
  cohorts: { status: ReduxStatus; data: ICohort[]; msg: ReduxMsg }
  students: { status: ReduxStatus; data: IStudent[]; msg: ReduxMsg }
  user_profile: { status: ReduxStatus; data: User; msg: ReduxMsg }
}) => ({
  students,
  cohorts,
  userProfile,
})

const mapDispatch = { fetchStudents }
const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>

function Students({
  students: { status: students_status, msg: student_msg, data: students },
  userProfile: { status: userProfile_status, data: userProfile },
  fetchStudents,
}: PropsFromRedux) {
  const match = useRouteMatch()
  const history = useHistory()
  const { emitSnackBarAlert } = useSnackBar()
  const pathname = location.pathname.split("/")
  const cohortName = pathname[2].toLowerCase()

  const handleStudentUrl = (name: string) => {
    const pathSuffix = name.split(" ").join("-")
    return `/cohorts/${cohortName}/${pathSuffix}`
  }
  const studentsWithFourPillars = students.map((student) => {
    const updatedStudent = { ...student, summary: {} } as IStudent
    if (student.form_submissions) {
      student.form_submissions.forEach((form) => {
        if (!form.headers || !form.rows) return
        const getFormName = (name: FormTemplate) =>
          name
            .toLowerCase()
            .split("_")
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join(" ")
        switch (form.form_name) {
          case getFormName(FormTemplate.STAFF_NOTES): {
            const headers = form.headers as string[]
            const relevantIdx = headers.indexOf("Primary Student's Name")
            const timestampIdx = headers.indexOf("Timestamp")
            const submissions = form.rows
            const form_name = `${form.form_name} ${form.module_name} ${form.form_number}`
            submissions.forEach((submission) => {
              const timestamp = submission[timestampIdx]
              if (submission[relevantIdx] !== student.name) return
              submission.forEach((value, rowIdx) => {
                if (value.length === 0) return
                if (!updatedStudent.summary[headers[rowIdx]]) {
                  updatedStudent.summary[headers[rowIdx]] = [
                    {
                      timestamp,
                      value,
                      form_name,
                    },
                  ]
                } else {
                  updatedStudent.summary[headers[rowIdx]].push({
                    timestamp,
                    value,
                    form_name,
                  })
                }
              })
            })
            break
          }
          case getFormName(FormTemplate.PAIRING_OBSERVATION): {
            const headers = form.headers as string[]
            const studentOne = headers.findIndex(
              (header) => header === "Student One"
            )
            const studentTwo = headers.findIndex(
              (header) => header === "Student Two"
            )

            const timestampIdx = headers.findIndex(
              (header) => header === "Timestamp"
            )
            form.rows.forEach((row) => {
              const isStudentOne = row[studentOne] === student.name
              const isStudentTwo = row[studentTwo] === student.name
              if (!isStudentOne && !isStudentTwo) return
              // hashmap to track whether question was asked
              const questionEncounter = {} as any
              row.forEach((value, rowIdx) => {
                if (value.length === 0) return
                if (isStudentOne && !questionEncounter[headers[rowIdx]]) {
                  if (!updatedStudent.summary[headers[rowIdx]]) {
                    updatedStudent.summary[headers[rowIdx]] = [
                      {
                        timestamp: row[timestampIdx],
                        value,
                        form: `${form.form_name} ${form.module_name} ${form.form_number}`,
                      },
                    ]
                  } else {
                    updatedStudent.summary[headers[rowIdx]].push({
                      timestamp: row[timestampIdx],
                      form: `${form.form_name} ${form.module_name} ${form.form_number}`,
                      value,
                    })
                  }
                }

                if (isStudentTwo && questionEncounter[headers[rowIdx]]) {
                  if (!updatedStudent.summary[headers[rowIdx]]) {
                    updatedStudent.summary[headers[rowIdx]] = [
                      {
                        timestamp: row[timestampIdx],
                        value,
                        form: `${form.form_name} ${form.module_name} ${form.form_number}`,
                      },
                    ]
                  } else {
                    updatedStudent.summary[headers[rowIdx]].push({
                      timestamp: row[timestampIdx],
                      value,
                      form: `${form.form_name} ${form.module_name} ${form.form_number}`,
                    })
                  }
                }

                questionEncounter[headers[rowIdx]] = true
              })
            })
            break
          }
          case getFormName(FormTemplate.HELP_DESK): {
            const headers = form.headers as string[]
            const studentOne = headers.findIndex(
              (header) => header === "Student One"
            )
            const studentTwo = headers.findIndex(
              (header) => header === "Student Two"
            )

            const timestampIdx = headers.findIndex(
              (header) => header === "Timestamp"
            )
            form.rows.forEach((row) => {
              const isStudentOne = row[studentOne] === student.name
              const isStudentTwo = row[studentTwo] === student.name
              if (!isStudentOne && !isStudentTwo) return
              // hashmap to track whether question was asked
              const questionEncounter = {} as any
              row.forEach((value, rowIdx) => {
                if (value.length === 0) return
                if (isStudentOne && !questionEncounter[headers[rowIdx]]) {
                  if (!updatedStudent.summary[headers[rowIdx]]) {
                    updatedStudent.summary[headers[rowIdx]] = [
                      {
                        timestamp: row[timestampIdx],
                        value,
                        form: `${form.form_name} ${form.module_name} ${form.form_number}`,
                      },
                    ]
                  } else {
                    updatedStudent.summary[headers[rowIdx]].push({
                      timestamp: row[timestampIdx],
                      form: `${form.form_name} ${form.module_name} ${form.form_number}`,
                      value,
                    })
                  }
                }

                if (isStudentTwo && questionEncounter[headers[rowIdx]]) {
                  if (!updatedStudent.summary[headers[rowIdx]]) {
                    updatedStudent.summary[headers[rowIdx]] = [
                      {
                        timestamp: row[timestampIdx],
                        value,
                        form: `${form.form_name} ${form.module_name} ${form.form_number}`,
                      },
                    ]
                  } else {
                    updatedStudent.summary[headers[rowIdx]].push({
                      timestamp: row[timestampIdx],
                      value,
                      form: `${form.form_name} ${form.module_name} ${form.form_number}`,
                    })
                  }
                }

                questionEncounter[headers[rowIdx]] = true
              })
            })
            break
          }
        }
      })
    }

    return updatedStudent
  })

  const copyToClipBoard = (text: string) => {
    // const copyText = `https://docs.google.com/forms/d/e/${props.formDetails.publish_id}/viewform`
    if (window.isSecureContext) {
      navigator.clipboard.writeText(text).then(function () {
        emitSnackBarAlert(`Copied! (${text})`)
      })
    } else {
      emitSnackBarAlert(`Could not copy! (${text})`)
    }
  }

  const studentSummary = studentsWithFourPillars.map((student) => {
    const studentListItem = { ...student } as IStudent & {
      T?: JSX.Element
      R?: JSX.Element
      A?: JSX.Element
      G?: JSX.Element
      copy_email: JSX.Element
      copy_github: JSX.Element
    }

    studentListItem.copy_email = (
      <Clipboard
        onClick={(e) => {
          e.stopPropagation()
          copyToClipBoard(student.email)
        }}
      >
        {"📋"}
      </Clipboard>
    )

    studentListItem.copy_github = (
      <Clipboard
        onClick={(e) => {
          e.stopPropagation()
          copyToClipBoard(student.github)
        }}
      >
        <div>{"📋"}</div>
        <div>{student.github}</div>
      </Clipboard>
    )
    const technicalAbility =
      student?.summary[FourPillars.TECHNICAL_ABILIY]?.length
    const rapportSocial = student?.summary[FourPillars.RAPPORT]?.length
    const accountability = student?.summary[FourPillars.ACCOUNTABILITY]?.length
    const grit = student?.summary[FourPillars.GRIT]?.length

    const getSet = (pillar: FourPillars) => (test: any) => {
      const filteredSet = student?.summary[pillar]?.filter(
        ({ timestamp }: { timestamp: string }) => test(new Date(timestamp))
      )
      const sumSet = filteredSet.reduce(
        (acc: string, { value }: { value: string }) => +acc + +value,
        0
      )
      return [
        sumSet === 0 ? 0 : sumSet / filteredSet.length,
        filteredSet.length,
      ] as [number, number]
    }

    if (technicalAbility > 0) {
      const technicalScores = getSet(FourPillars.TECHNICAL_ABILIY)
      const [thisWeek, countThisWeek] = technicalScores((date: Date) =>
        isSameWeek(date, startOfToday())
      )
      const [threeWeeks, countThreeWeeks] = technicalScores(
        (date: Date) => differenceInWeeks(startOfToday(), date) < 4
      )
      const [allTime, countAllTime] = technicalScores(() => true)

      studentListItem.T = (
        <table>
          <tbody>
            {tableRow("This Week", thisWeek, countThisWeek)}
            {tableRow("3 Weeks", threeWeeks, countThreeWeeks)}
            {tableRow("All Time", allTime, countAllTime)}
          </tbody>
        </table>
      )
    }
    if (rapportSocial > 0) {
      const rapportScores = getSet(FourPillars.RAPPORT)
      const [thisWeek, countThisWeek] = rapportScores((date: Date) =>
        isSameWeek(date, startOfToday())
      )
      const [threeWeeks, countThreeWeeks] = rapportScores(
        (date: Date) => differenceInWeeks(startOfToday(), date) < 4
      )
      const [allTime, countAllTime] = rapportScores(() => true)

      studentListItem.R = (
        <table>
          <tbody>
            {tableRow("This Week", thisWeek, countThisWeek)}
            {tableRow("3 Weeks", threeWeeks, countThreeWeeks)}
            {tableRow("All Time", allTime, countAllTime)}
          </tbody>
        </table>
      )
    }
    if (accountability > 0) {
      const accountabilityScores = getSet(FourPillars.ACCOUNTABILITY)
      const [thisWeek, countThisWeek] = accountabilityScores((date: Date) =>
        isSameWeek(date, startOfToday())
      )
      const [threeWeeks, countThreeWeeks] = accountabilityScores(
        (date: Date) => differenceInWeeks(startOfToday(), date) < 4
      )
      const [allTime, countAllTime] = accountabilityScores(() => true)

      studentListItem.A = (
        <table>
          <tbody>
            {tableRow("This Week", thisWeek, countThisWeek)}
            {tableRow("3 Weeks", threeWeeks, countThreeWeeks)}
            {tableRow("All Time", allTime, countAllTime)}
          </tbody>
        </table>
      )
    }
    if (grit > 0) {
      const gritScores = getSet(FourPillars.GRIT)
      const [thisWeek, countThisWeek] = gritScores((date: Date) =>
        isSameWeek(date, startOfToday())
      )
      const [threeWeeks, countThreeWeeks] = gritScores(
        (date: Date) => differenceInWeeks(startOfToday(), date) < 4
      )
      const [allTime, countAllTime] = gritScores(() => true)

      studentListItem.G = (
        <table>
          <tbody>
            {tableRow("This Week", thisWeek, countThisWeek)}
            {tableRow("3 Weeks", threeWeeks, countThreeWeeks)}
            {tableRow("All Time", allTime, countAllTime)}
          </tbody>
        </table>
      )
    }

    return studentListItem
  })
  info("studentSummary", studentSummary)
  return (
    <Container>
      <List
        textCenterMap={[false, true, true, false, false, false, false]}
        headers={[
          <H3
            onClick={() =>
              copyToClipBoard(
                students
                  .map(({ name, pronouns }) =>
                    pronouns ? `${name} (${pronouns})` : name
                  )
                  .join("\n")
              )
            }
            key={"preferred-name"}
          >
            Preferred Name
          </H3>,
          <H3
            onClick={() =>
              copyToClipBoard(students.map(({ email }) => email).join("\n"))
            }
            key={"preferred-name"}
          >
            Email
          </H3>,
          <GitHubIcon
            onClick={() =>
              copyToClipBoard(students.map(({ github }) => github).join("\n"))
            }
            key={"github-icon"}
          />,
          "Technical Abilities",
          "Accountability",
          "Rapport",
          "Grit",
          "",
        ]}
        headerFilters={[]}
        visibleContent={[
          "name",
          "copy_email",
          "copy_github",
          "T",
          "A",
          "R",
          "G",
          "",
        ]}
        columnWidths={[
          "250px",
          "100px",
          "160px",
          "160px",
          "160px",
          "160px",
          "160px",
          "50px",
        ]}
        listData={studentSummary}
        listItemUrlHandler={handleStudentUrl}
        listItemUrlVar={"name"}
      />
    </Container>
  )
}

export default connector(Students)
