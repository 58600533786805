import styled from "styled-components"
import { H4, P } from "../../base/styles.css"

export const Container = styled.div`
  width: 100%;
  > div {
    margin-top: 30px;
  }
`

export const StandardContainer = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  width: 98%;
  p {
    /* width: 600px; */
    margin-left: 20px;
  }
`

export const Level = styled.div`
  height: 20px;
  width: 20px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 20px;
  margin: auto;
`
export const LevelName = styled(H4)`
  word-break: word;
  text-align: center;
`
export const LevelContainer = styled.div`
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  justify-content: center;

  h4 {
    color: white;
  }
  h4:hover {
    position: relative;
  }
`
export const Title = styled.div`
  width: 98%;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  display: flex;
  flex-direction: row-reverse;
  font-family: ${({ theme }) => theme.font.family.lato};
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.text.normal};
  padding-bottom: 10px;
`
