import styled from "styled-components"
import { Form, Field } from "formik"
import { ButtonBase } from "@material-ui/core"
import GetAppIcon from "@material-ui/icons/GetApp"

export const P = styled.p`
  font-family: ${({ theme }) => theme.font.family.lora};
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-style: ${({ theme }) => theme.font.style.normal};
  color: ${({ theme }) => theme.color.text.normal};
  font-size: ${({ theme }) => theme.font.size.m};
  line-height: 1.5;
  white-space: pre-wrap;
`

export const ErrorP = styled(P)`
  color: red;
  font-family: ${({ theme }) => theme.font.family.lato};
`

export const A = styled.a`
  color: ${({ theme }) => theme.hyperlinkColor};
  text-decoration: underline;
  font-family: ${({ theme }) => theme.font.family.lato};
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-style: ${({ theme }) => theme.font.style.normal};
  font-size: ${({ theme }) => theme.font.size.m};
  &:hover {
    cursor: pointer;
  }
`

export const H0 = styled.h1`
  font-family: ${({ theme }) => theme.font.family.kansasnew};
  color: ${({ theme }) => theme.color.text.normal};
  font-size: ${({ theme }) => theme.font.size.xl};
  font-weight: normal;
  margin: 20px 0;
`

export const H1 = styled.h1`
  font-family: ${({ theme }) => theme.font.family.lato};
  color: ${({ theme }) => theme.color.text.normal};
  font-size: ${({ theme }) => theme.font.size.l};
  font-weight: ${({ theme }) => theme.font.weight.bolder};
  margin: 30px 0 50px 0;
`

export const H2 = styled.h2`
  font-family: ${({ theme }) => theme.font.family.lato};
  color: ${({ theme }) => theme.color.text.normal};
  font-size: ${({ theme }) => theme.font.size.ml};
  font-weight: bold;
  margin: 20px 0;
`

export const H3 = styled.h3`
  font-family: ${({ theme }) => theme.font.family.lato};
  color: ${({ theme }) => theme.color.text.normal};
  font-size: ${({ theme }) => theme.font.size.m};
  font-weight: bold;
  margin: 20px 0;
`

export const H4 = styled.h4`
  font-family: ${({ theme }) => theme.font.family.lato};
  color: ${({ theme }) => theme.color.text.normal};
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: bold;
  margin: 15px 0;
`

export const H5 = styled.h5`
  font-family: ${({ theme }) => theme.font.family.lato};
  color: ${({ theme }) => theme.color.text.normal};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: bold;
  margin: 15px 0;
`

export const Label = styled.label`
  font-family: ${({ theme }) => theme.font.family.lato};
  color: ${({ theme }) => theme.color.text.normal};
  font-size: ${({ theme }) => theme.font.size.m};
  font-weight: normal;
  margin: 10px 0 0 0;
`

export const LabelDescription = styled(P)`
  font-family: ${({ theme }) => theme.font.family.lato};
  color: ${({ theme }) => theme.color.text.light};
  font-size: ${({ theme }) => theme.font.size.m};
  font-weight: ${({ theme }) => theme.font.weight.light};
  margin: 5px 0;
`

export const UL = styled.ul`
  list-style-type: circle;
  margin-left: 20px;
  font-family: ${({ theme }) => theme.font.family.lato};
`

export const LI = styled.li`
  font-family: ${({ theme }) => theme.font.family.lato};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  font-style: ${({ theme }) => theme.font.style.normal};
  color: ${({ theme }) => theme.color.text.draft};
  font-size: ${({ theme }) => theme.font.size.m};
  line-height: 1;
  margin: 5px 0;
`

export const Spinner = styled.div`
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: ${({ theme }) => theme.color.text.normal};
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`

export const Download = styled(GetAppIcon)`
  &:hover {
    cursor: pointer;
  }
  color: ${({ theme }) => theme.color.text.normal};
  font-size: 2.5rem !important;
`

export const Button = styled(ButtonBase)`
  font-family: ${({ theme }) => theme.font.family.lato};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  font-size: ${({ theme }) => theme.font.size.m};
  color: ${({ theme }) => theme.color.text.normal}!important;
  background-color: ${({ theme }) => theme.backgroundColor.primary}!important;
  padding: 7px !important;
  border: 1px solid ${({ theme }) => theme.color.text.normal} !important;
  border-radius: 22px !important;
  transition: border color 0.15s ease-in-out;
  &:hover {
    background-color: ${({ theme }) =>
      theme.backgroundColor.secondary}!important;
    border: none;
    padding: 1%;
  }
  > .MuiTouchRipple-root span {
    background-color: ${({ theme }) => theme.backgroundColor.tertiary};
    opacity: 0.7;
  }
`
export const SubmitButton = styled(Button)`
  &:hover {
    color: ${({ theme }) => theme.backgroundColor.highlight}!important;
    border: 1px solid
      ${({ theme }) => theme.backgroundColor.highlight}!important;
    background-color: ${({ theme }) => theme.backgroundColor.primary}!important;
    padding: 1%;
  }
  > .MuiTouchRipple-root span {
    background-color: ${({ theme }) => theme.backgroundColor.highlight};
    opacity: 0.7;
  }
`

export const TextArea = styled.textarea`
  padding: 5px;
  color: ${({ theme }) => theme.color.text.draft};
  background-color: ${({ theme }) => theme.backgroundColor.primary};
  min-height: 1.5rem;
  font-size: ${({ theme }) => theme.font.size.m};
  font-family: ${({ theme }) => theme.font.family.lora};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  line-height: 1.5;
`
export const Select = styled.select`
  font-family: ${({ theme }) => theme.font.family.lato};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  font-size: ${({ theme }) => theme.font.size.m};
  color: ${({ theme }) => theme.color.text.normal}!important;
  background-color: ${({ theme }) => theme.backgroundColor.primary}!important;
  padding: 7px !important;
  border: 1px solid ${({ theme }) => theme.color.text.normal} !important;
  border-radius: 22px !important;
  transition: border color 0.15s ease-in-out;
  min-height: 1.5rem;
  line-height: 1.5;
  max-width: 200px;
  text-align: center;
  word-wrap: normal;
  &:hover {
    cursor: pointer;
  }
`

export const Upload = styled.input`
  color: transparent;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  &::before {
    content: "Select some files";
    color: black;
    display: inline-block;
    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  &:hover::before {
    border-color: black;
  }
  &:active {
    outline: 0;
  }
  &:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
`

export const FormikForm = styled(Form)`
  color: ${({ theme }) => theme.color};
`

export const FormikField = styled(Field)`
  padding: 5px;
  color: ${({ theme }) => theme.color.text.draft};
  background-color: ${({ theme }) => theme.backgroundColor.primary};
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  border: 2px solid ${({ theme }) => theme.borderColor};
  min-height: 1.5rem;
  font-size: ${({ theme }) => theme.font.size.m};
  font-family: ${({ theme }) => theme.font.family.lora};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  line-height: 1.5;
  &[type="checkbox"] {
    background-color: ${({ theme }) => theme.backgroundColor.primary};
    width: 20px;
    &:hover {
      cursor: pointer;
    }
  }
`

export const FieldContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`

export const FormErrorWhitespace = styled.div`
  height: 20px;
`

export const FormErrorDetails = styled.div`
  height: 20px;
`

export const DropDown = styled.div`
  min-width: 100px;
  min-height: 70px;
  background-color: ${({ theme }) => theme.backgroundColor.primary};
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  position: absolute;

  top: 20px;
  > div {
    display: flex;
    font-weight: ${({ theme }) => theme.font.weight.light};
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    &:first-child {
      margin-top: 10px;
    }
    > input {
      margin-right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
`

export const Sort = styled.div`
  min-width: 10px;
`
