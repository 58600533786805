import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  FixMe,
  ReduxStatus,
  GoogleID,
  LearningStandard,
  FormHistory,
} from "../../../global"
import { FormTemplate } from "../../enums"
import { form } from "../../services/api"

interface InitiaFormsState {
  status: ReduxStatus
  msg: string | null
  data: FormHistory[]
}

const initialState: InitiaFormsState = {
  status: "pending",
  msg: null,
  data: [],
}

export const createStaffNote = createAsyncThunk(
  "forms/createStaffNote",
  async ({
    formId,
    formHistoryId,
    formTemplateId,
    destinationFolderId,
    curriculumQuestions,
    formName,
    moduleName,
    cohortName,
    students,
    userEmail,
    userEmailGroup,
  }: {
    formId: FormTemplate
    formHistoryId: GoogleID
    formTemplateId: GoogleID
    destinationFolderId: GoogleID
    curriculumQuestions: LearningStandard[]
    formName: string
    moduleName: string
    cohortName: string
    students: string[]
    userEmail: string
    userEmailGroup: string
  }) => {
    return await form
      .create(
        formId,
        formHistoryId,
        formTemplateId,
        destinationFolderId,
        curriculumQuestions,
        formName,
        moduleName,
        cohortName,
        students,
        userEmail,
        userEmailGroup
      )
      .catch(({ message }) =>
        Promise.reject("Could not create Staff Note Form. " + message)
      )
  }
)

export const createPairingObservation = createAsyncThunk(
  "forms/createPairingObservation",
  async ({
    formId,
    formHistoryId,
    formTemplateId,
    destinationFolderId,
    curriculumQuestions,
    formName,
    moduleName,
    cohortName,
    students,
    userEmail,
    userEmailGroup,
  }: {
    formId: FormTemplate
    formHistoryId: GoogleID
    formTemplateId: GoogleID
    destinationFolderId: GoogleID
    curriculumQuestions: LearningStandard[]
    formName: string
    moduleName: string
    cohortName: string
    students: string[]
    userEmail: string
    userEmailGroup: string
  }) => {
    return await form
      .create(
        formId,
        formHistoryId,
        formTemplateId,
        destinationFolderId,
        curriculumQuestions,
        formName,
        moduleName,
        cohortName,
        students,
        userEmail,
        userEmailGroup
      )
      .catch(({ message }) =>
        Promise.reject("Could not create Pairing Observation Form. " + message)
      )
  }
)

export const createHelpDesk = createAsyncThunk(
  "forms/createHelpDesk",
  async ({
    formId,
    formHistoryId,
    formTemplateId,
    destinationFolderId,
    curriculumQuestions,
    formName,
    moduleName,
    cohortName,
    students,
    userEmail,
    userEmailGroup,
  }: {
    formId: FormTemplate
    formHistoryId: GoogleID
    formTemplateId: GoogleID
    destinationFolderId: GoogleID
    curriculumQuestions: LearningStandard[]
    formName: string
    moduleName: string
    cohortName: string
    students: string[]
    userEmail: string
    userEmailGroup: string
  }) => {
    return await form
      .create(
        formId,
        formHistoryId,
        formTemplateId,
        destinationFolderId,
        curriculumQuestions,
        formName,
        moduleName,
        cohortName,
        students,
        userEmail,
        userEmailGroup
      )
      .catch(({ message }) =>
        Promise.reject("Could not create Help Desk Form. " + message)
      )
  }
)

export const createSprintWrapUp = createAsyncThunk(
  "forms/createSprintWrapUp",
  async ({
    formId,
    formHistoryId,
    formTemplateId,
    destinationFolderId,
    curriculumQuestions,
    formName,
    moduleName,
    cohortName,
    students,
    userEmail,
    userEmailGroup,
  }: {
    formId: FormTemplate
    formHistoryId: GoogleID
    formTemplateId: GoogleID
    destinationFolderId: GoogleID
    curriculumQuestions: LearningStandard[]
    formName: string
    moduleName: string
    cohortName: string
    students: string[]
    userEmail: string
    userEmailGroup: string
  }) => {
    return await form
      .create(
        formId,
        formHistoryId,
        formTemplateId,
        destinationFolderId,
        curriculumQuestions,
        formName,
        moduleName,
        cohortName,
        students,
        userEmail,
        userEmailGroup
      )
      .catch(({ message }) =>
        Promise.reject("Could not create Sprint Wrap Up Form. " + message)
      )
  }
)

export const createWhiteboardReflection = createAsyncThunk(
  "forms/createWhiteboardReflection",
  async ({
    formId,
    formHistoryId,
    formTemplateId,
    destinationFolderId,
    curriculumQuestions,
    formName,
    moduleName,
    cohortName,
    students,
    userEmail,
    userEmailGroup,
  }: {
    formId: FormTemplate
    formHistoryId: GoogleID
    formTemplateId: GoogleID
    destinationFolderId: GoogleID
    curriculumQuestions: LearningStandard[]
    formName: string
    moduleName: string
    cohortName: string
    students: string[]
    userEmail: string
    userEmailGroup: string
  }) => {
    return await form
      .create(
        formId,
        formHistoryId,
        formTemplateId,
        destinationFolderId,
        curriculumQuestions,
        formName,
        moduleName,
        cohortName,
        students,
        userEmail,
        userEmailGroup
      )
      .catch(({ message }) =>
        Promise.reject(
          "Could not create Whiteboard Reflection Form. " + message
        )
      )
  }
)

const formsSlice = createSlice({
  name: "cohorts",
  initialState,
  reducers: {
    setForms(state, action) {
      state.status = "ready"
      state.data = action.payload
      state.msg = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      createStaffNote.fulfilled,
      (state, action: { payload: FixMe }) => {
        state.status = "ready"
        state.data = action.payload
        state.msg = null
      }
    )
    builder.addCase(createStaffNote.pending, (state) => {
      state.status = "pending"
    })
    builder.addCase(createStaffNote.rejected, (state, action) => {
      state.status = "error"
      state.msg = action.error.message + ""
    })
    builder.addCase(
      createHelpDesk.fulfilled,
      (state, action: { payload: FixMe }) => {
        state.status = "ready"
        state.data = action.payload
        state.msg = null
      }
    )
    builder.addCase(createHelpDesk.pending, (state) => {
      state.status = "pending"
    })
    builder.addCase(createHelpDesk.rejected, (state, action) => {
      state.status = "error"
      state.msg = action.error.message + ""
    })
    builder.addCase(
      createPairingObservation.fulfilled,
      (state, action: { payload: FixMe }) => {
        state.status = "ready"
        state.data = action.payload
        state.msg = null
      }
    )
    builder.addCase(createPairingObservation.pending, (state) => {
      state.status = "pending"
    })
    builder.addCase(createPairingObservation.rejected, (state, action) => {
      state.status = "error"
      state.msg = action.error.message + ""
    })
    builder.addCase(
      createSprintWrapUp.fulfilled,
      (state, action: { payload: FixMe }) => {
        state.status = "ready"
        state.data = action.payload
        state.msg = null
      }
    )
    builder.addCase(createSprintWrapUp.pending, (state) => {
      state.status = "pending"
    })
    builder.addCase(createSprintWrapUp.rejected, (state, action) => {
      state.status = "error"
      state.msg = action.error.message + ""
    })
    builder.addCase(
      createWhiteboardReflection.fulfilled,
      (state, action: { payload: FixMe }) => {
        state.status = "ready"
        state.data = action.payload
        state.msg = null
      }
    )
    builder.addCase(createWhiteboardReflection.pending, (state) => {
      state.status = "pending"
    })
    builder.addCase(createWhiteboardReflection.rejected, (state, action) => {
      state.status = "error"
      state.msg = action.error.message + ""
    })
  },
})

export const { setForms } = formsSlice.actions
export default formsSlice.reducer
