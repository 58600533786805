export enum Campus {
  NYC = "nyc",
  ATX = "atx",
  LAX = "lax",
}

export enum GateLevel {
  GATING = "gating",
  NON_GATING = "non_gating",
  GATING_EVENTUAL = "gating_eventual",
}

export enum FormTemplate {
  STAFF_NOTES = "STAFF_NOTES",
  PAIRING_OBSERVATION = "PAIRING_OBSERVATION",
  SPRINT_WRAP_UP = "SPRINT_WRAP_UP",
  HELP_DESK = "HELP_DESK",
  WHITEBOARD = "WHITEBOARD",
}

export enum FourPillars {
  TECHNICAL_ABILIY = "Technical Abilities",
  RAPPORT = "Rapport/Social",
  ACCOUNTABILITY = "Accountability/Professionalism",
  GRIT = "Grit/Self Management",
}

export enum StandardLevel {
  DEMONSTRATED_UNDERSTANDING = "Demonstrated Understanding",
  IMPLIED_UNDERSTANDING = "Implied Understanding",
  IMPLIED_MISUNDERSTANDING = "Implied Misunderstanding",
  DEMONSTRATED_MISUNDERSTANDING = "Demonstrated Misunderstanding",
}

export enum Colors {
  LIGHT_GREY = "lightgrey",
  GREY = "#4f566b",
  DARK_GREY = "rgb(60, 66, 87)",
  CHARCOAL = "#333333",
  DARK_SMOKE = "#2b2b2b",
  HR_BLUE = "aliceblue",
  OFF_WHITE = "#f7fafc",
  WHITE = "white",
}
