import reset from "styled-reset"
import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
 ${reset}

 @font-face {
      font-family: 'lato';
      src: url('/fonts/Lato-Black.ttf') format('truetype');
      font-weight: bolder;
      font-style: normal;
      font-display: swap;
   }

 @font-face {
      font-family: 'lato';
      src: url('/fonts/Lato-Bold.ttf') format('truetype');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'lato';
      src: url('/fonts/Lato-Regular.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'lato';
      src: url('/fonts/Lato-Light.ttf') format('truetype');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'lato';
      src: url('/fonts/Lato-Regular.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'lato';
      src: url('/fonts/Lato-Italic.ttf') format('truetype');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
   }

   @font-face {
      font-family: 'lora';
      src: url('/fonts/Lora-Italic.ttf') format('truetype');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
   }

   @font-face {
      font-family: 'lora';
      src: url('/fonts/Lora-Regular.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'lora';
      src: url('/fonts/Lora-Bold.ttf') format('truetype');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'kansasnew';
      src: url('/fonts/kansasnew.ttf') format('opentype');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
   }

 html{
 }

 div#root {
   width: 600px;
 }
`
