import React, { useEffect, useState } from "react"
import { getDay, formatDistanceToNow } from "date-fns"
import {
  CohortCalendarContainer,
  Calendar,
  DayContainer,
  DayNumber,
  DayName,
  DateContainer,
  MonthName,
  NumberCircle,
  DayNumberContainer,
  EventsContainer,
  EventContainer,
  TimeContainer,
  EventDetails,
  TimeDistance,
  JoinEvent,
  TimeBlock,
} from "./styles.css"
import { H4, P } from "../../../components/base/styles.css"
import Loading from "../../../components/base/loading"
import { FixMe } from "../../../../global"
import { useSnackBar } from "../../../hooks/useSnackbar"

const DAY_NAMES = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]
const MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
]
const formatCalendarEvent = (rawEvent: gapi.client.calendar.Event) => {
  let date, startTime, endTime, dayName, timeDistance, monthName
  if (!rawEvent)
    return {
      summary: "Error Loading",
      date: "10/10/21",
      start_time: "00:00:00",
      end_time: "00:00:00",
      location: "string | undefined;",
      all_day: false,
      day_name: "Monday",
      month_name: "Jan",
      time_distance: "string | null;",
    }
  if (
    rawEvent.start &&
    rawEvent.start.dateTime &&
    rawEvent.end &&
    rawEvent.end.dateTime
  ) {
    const [datePart, startTimePart] = rawEvent.start.dateTime.split("T")
    const [, endTimePart] = rawEvent.end.dateTime.split("T")
    const [hours, minutes, seconds] = startTimePart.slice(0, 8).split(":")
    const [year, month, day] = datePart.split("-")
    dayName = DAY_NAMES[getDay(new Date(+year, +month - 1, +day))]
    monthName = MONTH_NAMES[parseInt(month) - 1]
    timeDistance = formatDistanceToNow(
      new Date(+year, +month - 1, +day, +hours, +minutes, +seconds),
      {
        addSuffix: true,
        includeSeconds: true,
      }
    )
    startTime = startTimePart.slice(0, 5)
    endTime = endTimePart.slice(0, 5)
    date = `${month}/${day}/${year.slice(2)}`
  } else {
    const [year, month, day] = rawEvent.start?.date
      ? rawEvent.start.date.split("-")
      : ["1337", "01", "01"]
    dayName = DAY_NAMES[getDay(new Date(+year, +month - 1, +day))]
    monthName = MONTH_NAMES[parseInt(month) - 1]
    timeDistance = null
    date = `${month}/${day}/${year.slice(2)}`
  }

  return {
    summary: rawEvent.summary,
    date: date,
    start_time: startTime ? startTime : null,
    end_time: endTime ? endTime : null,
    location: rawEvent.location,
    all_day: endTime ? false : true,
    day_name: dayName,
    month_name: monthName,
    time_distance: timeDistance,
  }
}

interface CalendarEvent {
  summary: any
  date: string
  start_time: any
  end_time: any
  location: any
  all_day: boolean
  day_name: string
  month_name: string
  time_distance: string | null
}

const buildCalendar = (rawEvents: gapi.client.calendar.Event[]) => {
  const calendar = [
    [
      formatCalendarEvent(
        rawEvents.find(
          (event) => event.start && event.start.dateTime
        ) as gapi.client.calendar.Event
      ),
    ],
  ]
  for (let i = 0; i < [...rawEvents].slice(1).length; i++) {
    const rawEvent = rawEvents[i]
    if (!rawEvent.start || !rawEvent.start.dateTime) continue
    const formattedEvent = formatCalendarEvent(rawEvent)

    const lastIndex = calendar.length - 1
    const lastDate = calendar[lastIndex][0].date

    if (formattedEvent.date === lastDate) {
      calendar[lastIndex].push(formattedEvent)
    } else {
      const newDate = [formattedEvent]
      calendar.push(newDate)
    }
  }

  const finalizedCalendar = calendar.filter((day) =>
    day.filter(({ start_time }) => start_time)
  )
  return calendar
}

const CohortCalendar = (props: FixMe) => {
  const { calendar } = props
  let formattedCalendar: CalendarEvent[][] = []

  if (calendar.items.length) {
    formattedCalendar = buildCalendar(calendar.items)
  }
  const { emitSnackBarAlert } = useSnackBar()

  return (
    <CohortCalendarContainer>
      <Calendar>
        {formattedCalendar.length > 0 &&
          formattedCalendar.map((day: FixMe, idx: number) => {
            return (
              <DayContainer key={`day-${idx}`}>
                <DateContainer>
                  <DayNumberContainer>
                    <MonthName>{day[0].month_name}</MonthName>
                    <NumberCircle>
                      <DayNumber>{day[0].date.slice(3, 5)}</DayNumber>
                    </NumberCircle>
                  </DayNumberContainer>
                  <DayName> {day[0].day_name} </DayName>
                </DateContainer>
                <EventsContainer>
                  {day.map((event: FixMe, idy: number) => {
                    return (
                      <EventContainer key={`event-${idy}`}>
                        <TimeContainer>
                          <TimeBlock>
                            <H4>Start</H4>
                            <H4>{event.start_time}</H4>
                          </TimeBlock>
                          <hr />
                          <TimeBlock>
                            <H4>End</H4>
                            <H4>{event.end_time}</H4>
                          </TimeBlock>
                        </TimeContainer>
                        <EventDetails>
                          <H4>{event.summary}</H4>
                          <TimeDistance>{event.time_distance}</TimeDistance>
                          {event.location && (
                            <JoinEvent
                              onClick={() => {
                                emitSnackBarAlert(
                                  `Opening New Tab: ${event.location}`
                                )
                                setTimeout(
                                  () => window.open(event.location, "_blank"),
                                  2000
                                )
                              }}
                            >
                              Join Zoom
                            </JoinEvent>
                          )}
                        </EventDetails>
                      </EventContainer>
                    )
                  })}
                </EventsContainer>
              </DayContainer>
            )
          })}
      </Calendar>
    </CohortCalendarContainer>
  )
}

export default CohortCalendar
