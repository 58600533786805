import React from "react"
import { Button } from "./styles.css"
import { ArrowBackSharp } from "@material-ui/icons"
import styled from "styled-components"

const BaseButton = styled(Button)`
  color: ${({ theme }) => theme.backgroundColor.tertiary}!important;
  border: 1px solid ${({ theme }) => theme.color.text.normal}!important;
  border-radius: 50% !important;
  padding: 5px !important;
  margin-right: 25px !important;
  height: 30px;
  width: 30px;
  > svg {
    fill: ${({ theme }) => theme.color.text.normal};
  }
`

const BackButton = (props: { handleBackArrowClick: () => void }) => {
  return (
    <BaseButton onClick={props.handleBackArrowClick}>
      <ArrowBackSharp />
    </BaseButton>
  )
}

export default BackButton
