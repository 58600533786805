import styled from "styled-components"
import { P, H3, Button } from "../../../components/base/styles.css"

export const CohortCalendarContainer = styled.div``

export const Calendar = styled.div`
  display: flex;
  overflow-x: auto;
  height: 100%;
  width: 100%;
`

export const DayContainer = styled.div`
  min-width: 200px;
  margin-right: 8px;
  height: 470px;
`

export const DateContainer = styled.div`
  min-width: 200px;
  background-color: ${({ theme }) => theme.backgroundColor.primary};
  display: flex;
  margin-bottom: 10px;
`

export const MonthName = styled(P)`
  font-size: ${({ theme }) => theme.font.size.s};
  text-align: center;
  margin: 0 auto;
`

export const DayNumberContainer = styled.div``

export const NumberCircle = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.backgroundColor.primary};
  border: 1px solid ${({ theme }) => theme.color.text.normal};
`

export const DayNumber = styled(H3)`
  color: ${({ theme }) => theme.color.text.normal};
`

export const DayName = styled(P)`
  display: flex;
  margin-left: 15px;
  align-self: flex-end;
`

export const TimeDistance = styled(P)`
  font-size: ${({ theme }) => theme.font.size.s};
`

export const EventsContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 400px;
`

export const EventContainer = styled.div`
  display: flex;
  margin: 10px 5px 10px 0;
`

export const TimeContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  min-width: 50px;
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.backgroundColor.tertiary};
  border-left: 1px solid ${({ theme }) => theme.backgroundColor.tertiary};
  border-bottom: 1px solid ${({ theme }) => theme.backgroundColor.tertiary};
  display: flex;
  justify-content: center;
  flex-direction: column;
  > hr {
    width: 100%;
    color: ${({ theme }) => theme.color.primary};
  }
`

export const EventDetails = styled.div`
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border: 1px solid ${({ theme }) => theme.backgroundColor.tertiary};
  text-align: center;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > h4 {
    margin: 10px 0px;
    word-break: break-word;
  }
`

export const JoinEvent = styled(Button)`
  font-size: ${({ theme }) => theme.font.size.s};
  margin: 10px auto;
  margin-top: 15px !important;
  padding-top: 2px !important;
  padding-bottom: 4px !important;
  border-radius: 25px !important;
`

export const TimeBlock = styled.div``
