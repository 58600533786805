import styled from "styled-components"

export const Container = styled.table`
  width: 100%;
`

export const ListHeader = styled.thead`
  position: sticky;
  top: 101px;
  > tr {
    display: flex;
    flex-direction: row;
    background-color: ${({ theme }) => theme.backgroundColor.primary};
    > th td {
      font-weight: bold;
      background-color: ${({ theme }) => theme.backgroundColor.primary};
      &:hover {
        cursor: default;
      }
    }
  }
`
export const ListItemContainer = styled.tbody`
  > tr {
    border-top: 1px solid ${({ theme }) => theme.borderColor};
  }
  > tr:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  }
`

export const HeaderDetail = styled.th`
  font-family: ${({ theme }) => theme.font.family.lato};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.2;
  display: flex;
  align-items: center;
  min-height: 50px;
  word-break: break-word;
  height: 50px;
  z-index: 5;
  position: -webkit-sticky;
  position: sticky;
  top: 110px;
  background-color: ${({ theme }) => theme.backgroundColor.primary};
  color: ${({ theme }) => theme.color.text.draft};
  font-size: ${({ theme }) => theme.font.size.m};
  > h3,
  svg {
    color: ${({ theme }) => theme.color.text.normal};
    &:hover {
      cursor: pointer;
    }
  }
`

export const FilterContainer = styled.div`
  position: relative;
  min-width: 30px;
  min-height: 10px;
  &:hover {
    cursor: pointer;
  }
`
