import React, { useEffect, useState } from "react"
import { useSnackBar } from "../../hooks/useSnackbar"

import { connect, ConnectedProps } from "react-redux"
import { setUserInfo } from "./userProfileSlice"

import { LI, UL, Spinner } from "../../components/base/styles.css"
import { Container, UserProfileIcon, UserProfileMenu } from "./styles.css"

import { ReduxStatus, User } from "../../../global"
import { useHistory } from "react-router"
import { useLocation } from "react-router-dom"

const mapState = ({
  user_profile,
}: {
  user_profile: {
    status: ReduxStatus
    data: User
    msg: string | null
  }
}) => ({
  user_profile,
})
const mapDispatch = { setUserInfo }
const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux

function UserProfile({
  user_profile: {
    status: userProfile_status,
    data: userProfile,
    msg: userProfile_msg,
  },
}: Props) {
  const [isUserProfileToggled, toggleUserProfile] = useState<boolean>(false)
  const { emitSnackBarAlert, clearSnackBar } = useSnackBar()
  const history = useHistory()
  // const location = useLocation()

  useEffect(() => {
    if (userProfile_status === "error") {
      emitSnackBarAlert(userProfile_msg)
    }
    // if ((location?.state as { prevPath: string })?.prevPath === "/login") {
    //   clearSnackBar()
    // }
  }, [userProfile_status, userProfile_msg])

  return (
    <Container>
      {userProfile_status === "ready" ? (
        <UserProfileIcon onClick={() => history.push("/settings")}>
          {userProfile.first_name[0] + userProfile.last_name[0]}
        </UserProfileIcon>
      ) : (
        <Spinner />
      )}
    </Container>
  )
}

export default connector(UserProfile)
