import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { User, ReduxStatus, ReduxMsg, FixMe } from "../../../global"
import { user } from "../../services/api"

interface InitialUserProfileState {
  status: ReduxStatus
  msg: ReduxMsg
  data?: User
}

const initialState: InitialUserProfileState = {
  status: "pending",
  msg: null,
}

export const fetchUser = createAsyncThunk(
  "userProfile/fetch",
  async (google_user: {
    first_name: string
    last_name: string
    profile_url: string
    email: string
  }) =>
    await user
      .fetch(google_user.email)
      .then(({ campuses, campus_data_ids, campus_forms, email_groups }) => ({
        ...google_user,
        campuses,
        campus_data_ids,
        campus_forms,
        email_groups,
      }))
      .catch(({ message }) =>
        Promise.reject("Could not retrieve user from campus. " + message)
      )
)

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.status = "ready"
      state.data = Object.assign(state.data, action.payload)
    },
    setUserError(state, action) {
      state.status = "error"
      state.msg = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchUser.fulfilled,
      (state, action: { payload: FixMe }) => {
        state.status = "ready"
        state.data = action.payload
        state.msg = null
      }
    )
    builder.addCase(fetchUser.pending, (state) => {
      state.status = "pending"
    })
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.status = "error"
      state.msg = action.error.message + ""
    })
  },
})

export const { setUserInfo, setUserError } = userProfileSlice.actions

export default userProfileSlice.reducer
