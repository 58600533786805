import React from "react"
import { Container, Note, Chip } from "./styles.css"
import { useHistory } from "react-router-dom"
import {
  FormHistory,
  LearningStandard,
  StandardObservation,
} from "../../../../global"
import { P } from "../../base/styles.css"
import Title from "../title"
import FourPillars from "../fourPillars"
import Standards from "../standards"
import { FourPillars as FourPillarsEnum } from "../../../enums"
import { formatDistanceToNow } from "date-fns"

interface Props {
  form: FormHistory
  curriculum: LearningStandard[][]
}

const StaffNote = ({ form, curriculum }: Props) => {
  const { headers, rows, form_name, module_name, form_number } = form
  const history = useHistory()

  if (!rows || !rows[0]) history.push("/cohorts")

  const submission = (rows as string[][])[0] as string[]

  const note = submission[
    headers?.findIndex((h) => h === "Additional Notes") as number
  ] as string
  const additionalStudent = submission[
    headers?.findIndex((h) => h === "Additional Student (if present)") as number
  ] as string

  const author = `Author: ${(
    submission[
      headers?.findIndex((h) => h === "Email Address") as number
    ] as string
  )
    .split(".")
    .slice(0, 2)
    .map((word, idx) =>
      idx === 1
        ? word.split("@")[0][0].toUpperCase() + word.split("@")[0].slice(1)
        : word[0].toUpperCase() + word.slice(1)
    )
    .join(" ")}`

  const timestamp = `${
    submission[headers?.findIndex((h) => h === "Timestamp") as number] as string
  } (${formatDistanceToNow(
    new Date(
      submission[
        headers?.findIndex((h) => h === "Timestamp") as number
      ] as string
    ),
    { addSuffix: true }
  )})`

  const technical =
    parseInt(
      submission[
        headers?.findIndex(
          (h) => h === FourPillarsEnum.TECHNICAL_ABILIY
        ) as number
      ] as string
    ) || undefined

  const rapport =
    parseInt(
      submission[
        headers?.findIndex((h) => h === FourPillarsEnum.RAPPORT) as number
      ] as string
    ) || undefined

  const accountability =
    parseInt(
      submission[
        headers?.findIndex(
          (h) => h === FourPillarsEnum.ACCOUNTABILITY
        ) as number
      ] as string
    ) || undefined

  const grit =
    parseInt(
      submission[
        headers?.findIndex((h) => h === FourPillarsEnum.GRIT) as number
      ] as string
    ) || undefined
  const moduleStandards = curriculum.find(
    (module) => (module[0] as LearningStandard).module_name === module_name
  ) as LearningStandard[]

  const standardOservations = [] as StandardObservation[]
  ;(headers ? headers : ([] as string[])).forEach((question, idx) => {
    const modStd = moduleStandards.find(
      (modStd) => modStd.standard === question
    )
    if (modStd && submission[idx]) {
      standardOservations.push({ ...modStd, observation: submission[idx] })
    }
  })

  return (
    <Container>
      <Title
        title={`${form_name} ${module_name} ${form_number}`}
        author={author}
        timestamp={timestamp}
      />
      <Note>
        <P>
          <Chip>Notes</Chip>
          {additionalStudent
            ? note
              ? `${note} (additional student: ${additionalStudent})`
              : `(additional student: ${additionalStudent})`
            : note}
        </P>
      </Note>
      <FourPillars T={technical} R={rapport} A={accountability} G={grit} />
      <Standards standards={standardOservations} />
    </Container>
  )
}

export default StaffNote
