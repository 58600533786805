import baseStyled, { ThemedStyledInterface } from "styled-components"
import { Colors } from "../enums"

const common = {
  font: {
    size: {
      xs: ".7rem",
      s: ".8rem",
      m: "1rem",
      ml: "1.2rem",
      l: "1.5rem",
      xl: "1.8rem",
      xxl: "3rem",
    },
    family: {
      lato: "Lato, Helvetica",
      lora: "Lora, Helvetica",
      kansasnew: "Lora, Lora, serif",
    },
    weight: {
      light: 300,
      normal: "normal",
      semiBold: 500,
      bold: "bold",
      bolder: "bolder",
    },
    style: {
      normal: "normal",
      italic: "italic",
    },
  },
  form: {
    error: "red",
  },
  shadowReference: {
    card: "0 1px 0 rgba(9,30,66,.25)",
    sidebar: {
      light: `3px 2px 9px -5px ${Colors.CHARCOAL}`,
      dark: `3px 2px 9px -5px ${Colors.CHARCOAL}`,
    },
  },
}

const light = {
  status: "light",
  color: {
    text: {
      light: Colors.GREY,
      draft: Colors.DARK_GREY,
      normal: Colors.CHARCOAL,
      secondary: Colors.WHITE,
    },
  },
  borderColor: Colors.LIGHT_GREY,
  boxShadow: "0px 0px 7px -4px #bbbfc9",
  backgroundColor: {
    primary: Colors.WHITE,
    secondary: Colors.OFF_WHITE,
    tertiary: Colors.CHARCOAL,
    highlight: Colors.HR_BLUE,
    navbar: Colors.WHITE,
  },
  hyperlinkColor: "blueviolet",
  sideBar: {
    boxShadow: common.shadowReference.sidebar.light,
  },
  ...common,
}

const dark = {
  status: "dark",
  color: {
    text: {
      light: "whitesmoke",
      draft: "lightgray",
      normal: "whitesmoke",
      secondary: "#333333",
    },
  },
  borderColor: "grey",
  boxShadow: "0px 0px 1px 1px white",
  backgroundColor: {
    primary: Colors.CHARCOAL,
    secondary: Colors.DARK_SMOKE,
    tertiary: Colors.OFF_WHITE,
    highlight: Colors.WHITE,
    navbar: Colors.CHARCOAL,
  },
  hyperlinkColor: "fuchsia",
  sideBar: {
    boxShadow: common.shadowReference.sidebar.dark,
  },
  ...common,
}

export const themes = {
  light: { ...light },
  dark: { ...dark },
}

export type Themes = typeof themes
export const styled = baseStyled as ThemedStyledInterface<Themes>
