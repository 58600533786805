import React from "react"
import { Container, ThemeSwitch } from "./styles.css"
import UserProfile from "../../features/userProfile"

interface Props {
  toggleTheme: () => void
  theme: string
}

function TopNav({ toggleTheme, theme }: Props) {
  return (
    <Container>
      <UserProfile />
      <ThemeSwitch
        checked={theme === "light"}
        onChange={toggleTheme}
        name="theme-toggle"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    </Container>
  )
}

export default TopNav
