import styled from "styled-components"

export const Container = styled.tr`
  display: flex;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.backgroundColor.secondary};
  }
`
export const ItemDetail = styled.td`
  font-family: ${({ theme }) => theme.font.family.lato};
  color: ${({ theme }) => theme.color.text.normal};
  font-size: ${({ theme }) => theme.font.size.m};
  font-weight: ${({ theme }) => theme.font.weight.light};
  line-height: 1.2;
  display: flex;
  align-items: center;
  min-height: 50px;
  word-break: break-word;
`
