import React from "react"

import { connect, ConnectedProps } from "react-redux"
import { useHistory } from "react-router-dom"
import { ReduxMsg, ReduxStatus } from "../../../global"
import { useSnackBar } from "../../hooks/useSnackbar"

import { H0, P } from "../base/styles.css"
import { Container } from "./styles.css"

type PropsFromRedux = ConnectedProps<typeof connector>

const mapState = ({
  students,
  user_profile: userProfile,
  cohorts,
}: {
  students: { status: ReduxStatus; msg: ReduxMsg }
  user_profile: { status: ReduxStatus; msg: ReduxMsg }
  cohorts: { status: ReduxStatus; msg: ReduxMsg }
}) => ({ students, cohorts, userProfile })

const connector = connect(mapState, {})

const Error = ({
  students: { status: students_status, msg: students_msg },
  userProfile: { status: userProfile_status, msg: userProfile_msg },
  cohorts: { status: cohorts_status, msg: cohorts_msg },
}: PropsFromRedux) => {
  const history = useHistory()
  const noError = [students_status, userProfile_status, cohorts_status].every(
    (status) => status !== "error"
  )

  if (noError) {
    history.push("/")
  }

  const { clearSnackBar } = useSnackBar()

  clearSnackBar()

  return (
    <Container>
      <H0>Whoops! 🤦‍♀️🤦‍🤦‍♂️</H0>
      <div>
        {cohorts_status === "error" && <P>{cohorts_msg}</P>}
        {userProfile_status === "error" && <P>{userProfile_msg}</P>}
        {students_status === "error" && <P>{students_msg}</P>}
      </div>
    </Container>
  )
}

export default connector(Error)
