import React, { useState } from "react"

import { Container } from "./styles.css"

function Login() {
  return (
    <Container>
      <div>
        Login. You should see a popup asking you to sign in using your google
        creds.
      </div>
    </Container>
  )
}

export default Login
