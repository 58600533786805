import _ from "lodash"
import {
  Cohort,
  FixMe,
  GoogleID,
  LearningStandard,
  User,
  Form,
  FormHistory,
  CreateFormResponsePayload,
  SpreadsheetAPI,
  Student,
  WhiteboardPrompt,
} from "../../global"
import {
  CURRICULUM_ID,
  GEN_HELP_DESK_APP_SCRIPT_ID,
  GEN_PAIRING_OBSERVATION_APP_SCRIPT_ID,
  GEN_SPRINT_WRAP_UP_APP_SCRIPT_ID,
  GEN_STAFF_NOTES_APP_SCRIPT_ID,
  GEN_WHITEBOARD_REFLECTION_APP_SCRIPT_ID,
  PROMPTS_ID,
  USER_DATA_SOURCES,
} from "../constants"
import { FormTemplate } from "../enums"
import { getCurrentDate, dateRank } from "../utils"
import {
  execAppScript,
  fetchCalendar,
  fetchSheets,
  fetchSheetsBatch,
} from "./google"

/**
 * @function user
 * @description fetches user
 **/

export const user = {
  fetch: async (userEmail: string) => {
    try {
      // fetch user
      const userAccount = await fetchSheets(USER_DATA_SOURCES, "users!A:Z")
        .then(({ values }: { values: string[][] }) => {
          const headers = values[0]
          const userValues = values
            .slice(1)
            .find((row) =>
              row.some((val: string) => val === userEmail)
            ) as string[]

          return headers.reduce((acc: FixMe, el: FixMe, idx: number) => {
            acc[el] = userValues[idx] as string
            return acc
          }, {} as FixMe)
        })
        .catch((err) => Promise.reject(err))
      // fetch ordered campus set
      const campuses = userAccount.campuses.replaceAll(" ", "").split(",")
      // fetch campus data
      const campusData = await fetchSheets(USER_DATA_SOURCES, "campuses!A:Z")
        .then(({ values }: { values: string[][] }) => {
          const headers = values[0]
          const campusesSelected = values
            .slice(1)
            .filter((row) =>
              row.some((val: string) => campuses.includes(val))
            ) as string[][]

          return campusesSelected.map((campus) =>
            headers.reduce((acc: FixMe, el: FixMe, idx: number) => {
              acc[el] = campus[idx] as string
              return acc
            }, {} as FixMe)
          )
        })
        .catch((err) => Promise.reject(err))
      // get ordered campus_data_id set (same as campuses)
      const campusIds = campuses.map(
        (campusName: string) =>
          (
            campusData.find(({ name }) => name === campusName) || {
              campus_data_id: "undefined",
            }
          ).campus_data_id
      )
      // get ordered enail_groups set (same as campuses)
      const campusGroups = campuses.map(
        (campusName: string) =>
          (
            campusData.find(({ name }) => name === campusName) || {
              campus_data_id: "undefined",
            }
          ).email_group
      )
      // get all forms
      const campusForms: FixMe = await Promise.all(
        campusData.map(({ name, campus_data_id }) =>
          fetchSheets(campus_data_id, "form_templates!A:Z")
            .then(({ values }: { values: string[][] }) => {
              const headers = values[0]
              const formTemplateValues = values.slice(1)
              return formTemplateValues.map(
                (ftv) =>
                  headers.reduce(
                    (acc, el, idx) => {
                      acc[el as string] = ftv[idx]
                      return acc
                    },
                    { campus: name } as FixMe
                  ) as FixMe
              )
            })
            .catch((err) => Promise.reject(err))
        )
      ).then((forms) => forms.reduce((acc, el) => acc.concat(el), []))
      // return user and all campus data user is subscribed to
      return {
        ...(userAccount as User),
        email_groups: campusGroups,
        campus_data_ids: campusIds,
        campuses,
        campus_forms: campusForms as Form[],
      } as User
    } catch (err) {
      return Promise.reject(err)
    }
  },
}
/**
 * @function curriculum
 * @description fetches curriculum
 **/

export const curriculum = {
  fetch: async () => {
    return await fetchSheets(CURRICULUM_ID, "standards!A:Z")
      .then(({ values }: { values: string[][] }) => {
        const headers = values[0]
        const curriculumStandards = values.slice(1).map(
          (standard) =>
            headers.reduce((acc, el, idx) => {
              acc[el as string] = standard[idx]
              return acc
            }, {} as FixMe) as LearningStandard
        )

        const curriculum: LearningStandard[][] = []
        const modules: FixMe = {}

        curriculumStandards.forEach((standard) => {
          if (modules[standard.module_name] === undefined) {
            curriculum.push([standard])
            modules[standard.module_name] = curriculum.length - 1
          } else {
            curriculum[modules[standard.module_name]].push(standard)
          }
        })
        return curriculum
      })
      .catch((err) => Promise.reject(err))
  },
}
/**
 * @function whiteboardPrompts
 * @description fetches whiteboarding prompts
 **/

export const whiteboardPrompts = {
  fetch: async () => {
    return await fetchSheets(PROMPTS_ID, "whiteboard_prompts!A:Z")
      .then(({ values }: { values: string[][] }) => {
        const headers = values[0]
        return values.slice(1).map(
          (standard) =>
            headers.reduce((acc, el, idx) => {
              acc[el as string] = standard[idx]
              return acc
            }, {} as FixMe) as WhiteboardPrompt
        )
      })
      .catch((err) => Promise.reject(err))
  },
}
/**
 * @function cohorts
 * @description fetches cohorts that have both started after today (with leadTime)
 * and end after/on today.
 **/

export const cohort = {
  fetch: async (campus_data_id: GoogleID) => {
    return await fetchSheets(campus_data_id, "cohorts!A:Z")
      .then(async ({ values }: { values: string[][] }) => {
        const headers = values[0]
        const rows = values.slice(1)
        return Promise.all(
          rows
            // map to objects
            .map(
              (cohortArr) =>
                headers.reduce((acc, el, idx) => {
                  acc[el as string] = cohortArr[idx]
                  return acc
                }, {} as FixMe) as FixMe
            )
            // filter cohorts expired
            .filter(({ start_date, end_date }) => {
              const leadTime = 7
              const didCohortStartBeforeToday =
                dateRank(start_date) - leadTime <= dateRank(getCurrentDate())
              const doesCohortEndAfterToday =
                dateRank(end_date) >= dateRank(getCurrentDate())
              return didCohortStartBeforeToday && doesCohortEndAfterToday
            })
            // fetch calendar for cohort
            .map(async (cohort: Cohort) => {
              const calendar = await fetchCalendar(cohort.calendar_id)
              const form_history = (await fetchSheets(
                cohort.form_history_id,
                "form_generation_log!A:Z"
              )
                // fetch form history
                .then(async ({ values }: { values: string[][] }) => {
                  const headers = values[0]
                  //map form history to objects
                  return values.slice(1).map(
                    (cohortArr) =>
                      headers.reduce((acc, el, idx) => {
                        acc[el as string] = cohortArr[idx]
                        return acc
                      }, {} as FixMe) as FixMe
                  )
                })) as FormHistory[]

              return {
                ...cohort,
                calendar,
                form_history,
              }
            })
        )
      })
      .catch((err) => Promise.reject(err))
  },

  fetchForms: (cohorts: Cohort[]) => {
    return Promise.all(
      cohorts.map(
        async (cohort) =>
          new Promise(async (resolve, reject) => {
            // get unique form response ids
            const availableForms = new Set()
            cohort.form_history?.forEach(({ response_id }) =>
              availableForms.add(response_id)
            )
            // fetch all form data from cohort
            const allFormGroupResponses = await Promise.all(
              ([...availableForms] as string[]).map((response_id) => {
                const forms = cohort.form_history?.filter(
                  (form) => form.response_id === response_id
                ) as FormHistory[]
                const ranges = forms.map(
                  ({ form_number, module_name }) =>
                    `${module_name} ${form_number}`
                )
                return fetchSheetsBatch(response_id, ranges)
              })
            )

            const formHistoryWithResponses = await Promise.all(
              (cohort.form_history as FormHistory[]).map(
                (form) =>
                  new Promise((resolve, reject) => {
                    const {
                      response_id: responseId,
                      form_number: srcFormNumber,
                      module_name: srcModuleName,
                    } = form
                    const formGroupResponses = allFormGroupResponses.find(
                      ({ spreadsheetId }) => spreadsheetId === responseId
                    )
                    if (!formGroupResponses)
                      throw new TypeError(
                        `could not find spreadsheet_id: ${responseId} in formGroupResponses`
                      )
                    const { valueRanges: formGroupResponseValues } =
                      formGroupResponses
                    const parseRange = (range: SpreadsheetAPI.SSRange) => {
                      const [, title] = range.split("'")
                      if (!title)
                        reject(
                          new TypeError(
                            `Could not parse "title" from range: ${range}`
                          )
                        )
                      const titleChunks = title.split(" ")
                      const form_number = titleChunks.pop()
                      const module_name = titleChunks.join(" ")
                      if (!module_name || !form_number)
                        reject(
                          new TypeError(
                            `Could not parse "module_name" or "form_number" from range: ${range}`
                          )
                        )
                      return {
                        module_name,
                        form_number,
                      }
                    }

                    const formResponses = formGroupResponseValues.find(
                      ({ range }) => {
                        const { module_name, form_number } = parseRange(range)
                        return (
                          srcFormNumber === form_number &&
                          module_name === srcModuleName
                        )
                      }
                    )

                    if (!formResponses)
                      reject(
                        new TypeError(
                          `Could not find ${srcModuleName} ${srcFormNumber} in formResponses`
                        )
                      )
                    const [headers] = (
                      formResponses as SpreadsheetAPI.FormResponse
                    ).values
                    const rows = (
                      formResponses as SpreadsheetAPI.FormResponse
                    ).values.slice(1)
                    resolve({ ...form, headers, rows })
                  })
              )
            )
            resolve({ ...cohort, form_history: formHistoryWithResponses })
          })
      )
    )
  },
}

/**
 * @function student
 * @description fetches students for a given cohort
 **/
export const student = {
  fetch: async (cohort_name: string, campus_data_id: GoogleID) => {
    return await fetchSheets(campus_data_id, "students!A:Z")
      .then(({ values }: { values: string[][] }) => {
        const headers = values[0]
        return values
          .slice(1)
          .filter((studentRecord) =>
            studentRecord.some((val: string) => val === cohort_name)
          )
          .map(
            (studentArr) =>
              headers.reduce((acc, el, idx) => {
                acc[el as string] = studentArr[idx]
                return acc
              }, {} as FixMe) as FixMe
          )
          .filter(({ status }) => status === "enrolled")
      })
      .catch((err) => Promise.reject(err))
  },
  sortSubmissions: async (
    cohorts: Cohort[],
    cohort_name: string,
    students: Student[]
  ) => {
    const cohort = cohorts.find(({ name }) => cohort_name === name) as Cohort
    if (!cohort || !cohort.form_history)
      throw new Error(`${cohort_name} not found`)
    return Promise.all(
      students.map(
        (student) =>
          new Promise((resolve, reject) => {
            const form_submissions = cohort.form_history
              ?.map((form) => ({
                ...form,
                rows: form.rows?.filter((row) =>
                  row.some(
                    (value) => value === student.name || value === student.email
                  )
                ),
              }))
              .filter((form) => form && form.rows && form.rows.length > 0)

            resolve({ ...student, form_submissions })
          })
      )
    )
  },
}

/**
 * @function staffNote
 * @description api for managing staff notes
 **/
export const form = {
  create: async (
    formId: FormTemplate,
    formHistoryId: GoogleID,
    formTemplateId: GoogleID,
    destinationFolderId: GoogleID,
    curriculumQuestions: LearningStandard[],
    formName: string,
    moduleName: string,
    cohortName: string,
    students: string[],
    userEmail: string,
    userEmailGroup: string
  ) => {
    try {
      //
      const lastForm = await fetchSheets(
        formHistoryId,
        "form_generation_log!A:Z"
      ).then(({ values }: { values: string[][] }) => {
        const headers = values[0]
        return (
          values.slice(1).map(
            (formHistoryRecord) =>
              headers.reduce((acc, el, idx) => {
                acc[el as string] = formHistoryRecord[idx]
                return acc
              }, {} as FixMe) as FixMe
          ) as FormHistory[]
        )
          .filter(({ form_name }) => form_name === formName)
          .sort(({ created_at: a }, { created_at: b }) => {
            const dateA = new Date(
              a
                .split("/")
                .reverse()
                .map((num) =>
                  parseInt(num) < 10 ? String(num).padStart(2, "0") : num
                )
                .join("")
            )
            const dateB = new Date(
              b
                .split("/")
                .reverse()
                .map((num) =>
                  parseInt(num) < 10 ? String(num).padStart(2, "0") : num
                )
                .join("")
            )

            return dateA < dateB ? -1 : dateA > dateB ? 1 : 0
          })
      })

      const lastFormByModule = lastForm
        .filter(({ module_name }) => module_name === moduleName)
        .sort(
          ({ form_number: a }, { form_number: b }) => parseInt(a) - parseInt(b)
        )
        .pop()

      switch (formId) {
        case FormTemplate.WHITEBOARD: {
          // create sprint wrap up
          const newFormValues = (await execAppScript(
            formName,
            lastFormByModule ? parseInt(lastFormByModule.form_number) + 1 : 1,
            cohortName,
            students,
            moduleName,
            formTemplateId,
            destinationFolderId,
            curriculumQuestions,
            GEN_WHITEBOARD_REFLECTION_APP_SCRIPT_ID,
            userEmailGroup,
            lastForm.length > 0 ? lastForm[0].response_id : undefined
          )) as CreateFormResponsePayload

          const rows = [
            [
              getCurrentDate(), //created_at
              cohortName, //cohort_name
              formTemplateId, //form_template_id
              formName, //form_name
              moduleName, //module_name
              lastFormByModule ? parseInt(lastFormByModule.form_number) + 1 : 1, //form_number
              newFormValues.response_id, //response_id
              newFormValues.edit_id, //edit_id
              newFormValues.publish_id, //publish_id
              newFormValues.sheet_id, //sheet_id
              userEmail, //createdBy
            ],
          ]

          const request = {
            spreadsheetId: formHistoryId,
            range: "form_generation_log",
            valueInputOption: "USER_ENTERED",
            insertDataOption: "INSERT_ROWS",
            resource: {
              values: rows,
            },
          }
          //append to cohort form history
          await gapi.client.sheets.spreadsheets.values
            .append(request)
            .then((response) => {
              if (response.status !== 200) {
                throw new Error(
                  `Error appending to https://docs.google.com/spreadsheets/d/${formHistoryId}`
                )
              }
              return JSON.parse(response.body)
            })

          return newFormValues
        }
        case FormTemplate.SPRINT_WRAP_UP: {
          // create sprint wrap up
          const newFormValues = (await execAppScript(
            formName,
            lastFormByModule ? parseInt(lastFormByModule.form_number) + 1 : 1,
            cohortName,
            students,
            moduleName,
            formTemplateId,
            destinationFolderId,
            curriculumQuestions,
            GEN_SPRINT_WRAP_UP_APP_SCRIPT_ID,
            userEmailGroup,
            lastForm.length > 0 ? lastForm[0].response_id : undefined
          )) as CreateFormResponsePayload

          const rows = [
            [
              getCurrentDate(), //created_at
              cohortName, //cohort_name
              formTemplateId, //form_template_id
              formName, //form_name
              moduleName, //module_name
              lastFormByModule ? parseInt(lastFormByModule.form_number) + 1 : 1, //form_number
              newFormValues.response_id, //response_id
              newFormValues.edit_id, //edit_id
              newFormValues.publish_id, //publish_id
              newFormValues.sheet_id, //sheet_id
              userEmail, //createdBy
            ],
          ]

          const request = {
            spreadsheetId: formHistoryId,
            range: "form_generation_log",
            valueInputOption: "USER_ENTERED",
            insertDataOption: "INSERT_ROWS",
            resource: {
              values: rows,
            },
          }
          //append to cohort form history
          await gapi.client.sheets.spreadsheets.values
            .append(request)
            .then((response) => {
              if (response.status !== 200) {
                throw new Error(
                  `Error appending to https://docs.google.com/spreadsheets/d/${formHistoryId}`
                )
              }
              return JSON.parse(response.body)
            })

          return newFormValues
        }
        case FormTemplate.STAFF_NOTES: {
          // create staff notes
          const newFormValues = (await execAppScript(
            formName,
            lastFormByModule ? parseInt(lastFormByModule.form_number) + 1 : 1,
            cohortName,
            students,
            moduleName,
            formTemplateId,
            destinationFolderId,
            curriculumQuestions,
            GEN_STAFF_NOTES_APP_SCRIPT_ID,
            userEmailGroup,
            lastForm.length > 0 ? lastForm[0].response_id : undefined
          )) as CreateFormResponsePayload

          const rows = [
            [
              getCurrentDate(), //created_at
              cohortName, //cohort_name
              formTemplateId, //form_template_id
              formName, //form_name
              moduleName, //module_name
              lastFormByModule ? parseInt(lastFormByModule.form_number) + 1 : 1, //form_number
              newFormValues.response_id, //response_id
              newFormValues.edit_id, //edit_id
              newFormValues.publish_id, //publish_id
              newFormValues.sheet_id, //sheet_id
              userEmail, //createdBy
            ],
          ]

          const request = {
            spreadsheetId: formHistoryId,
            range: "form_generation_log",
            valueInputOption: "USER_ENTERED",
            insertDataOption: "INSERT_ROWS",
            resource: {
              values: rows,
            },
          }
          //append to cohort form history
          await gapi.client.sheets.spreadsheets.values
            .append(request)
            .then((response) => {
              if (response.status !== 200) {
                throw new Error(
                  `Error appending to https://docs.google.com/spreadsheets/d/${formHistoryId}`
                )
              }
              return JSON.parse(response.body)
            })

          return newFormValues
        }
        case FormTemplate.HELP_DESK: {
          const newFormValues = (await execAppScript(
            formName,
            lastFormByModule ? parseInt(lastFormByModule.form_number) + 1 : 1,
            cohortName,
            students,
            moduleName,
            formTemplateId,
            destinationFolderId,
            curriculumQuestions,
            GEN_HELP_DESK_APP_SCRIPT_ID,
            userEmailGroup,
            lastForm.length > 0 ? lastForm[0].response_id : undefined
          )) as CreateFormResponsePayload

          const rows = [
            [
              getCurrentDate(), //created_at
              cohortName, //cohort_name
              formTemplateId, //form_template_id
              formName, //form_name
              moduleName, //module_name
              lastFormByModule ? parseInt(lastFormByModule.form_number) + 1 : 1, //form_number
              newFormValues.response_id, //response_id
              newFormValues.edit_id, //edit_id
              newFormValues.publish_id, //publish_id
              newFormValues.sheet_id, //sheet_id
              userEmail, //createdBy
            ],
          ]

          const request = {
            spreadsheetId: formHistoryId,
            range: "form_generation_log",
            valueInputOption: "USER_ENTERED",
            insertDataOption: "INSERT_ROWS",
            resource: {
              values: rows,
            },
          }
          //append to cohort form history
          await gapi.client.sheets.spreadsheets.values
            .append(request)
            .then((response) => {
              if (response.status !== 200) {
                throw new Error(
                  `Error appending to https://docs.google.com/spreadsheets/d/${formHistoryId}`
                )
              }
              return JSON.parse(response.body)
            })

          return newFormValues
        }
        case FormTemplate.PAIRING_OBSERVATION: {
          const newFormValues = (await execAppScript(
            formName,
            lastFormByModule ? parseInt(lastFormByModule.form_number) + 1 : 1,
            cohortName,
            students,
            moduleName,
            formTemplateId,
            destinationFolderId,
            curriculumQuestions,
            GEN_PAIRING_OBSERVATION_APP_SCRIPT_ID,
            userEmailGroup,
            lastForm.length > 0 ? lastForm[0].response_id : undefined
          )) as CreateFormResponsePayload

          const rows = [
            [
              getCurrentDate(), //created_at
              cohortName, //cohort_name
              formTemplateId, //form_template_id
              formName, //form_name
              moduleName, //module_name
              lastFormByModule ? parseInt(lastFormByModule.form_number) + 1 : 1, //form_number
              newFormValues.response_id, //response_id
              newFormValues.edit_id, //edit_id
              newFormValues.publish_id, //publish_id
              newFormValues.sheet_id, //sheet_id
              userEmail, //createdBy
            ],
          ]

          const request = {
            spreadsheetId: formHistoryId,
            range: "form_generation_log",
            valueInputOption: "USER_ENTERED",
            insertDataOption: "INSERT_ROWS",
            resource: {
              values: rows,
            },
          }
          //append to cohort form history
          await gapi.client.sheets.spreadsheets.values
            .append(request)
            .then((response) => {
              if (response.status !== 200) {
                throw new Error(
                  `Error appending to https://docs.google.com/spreadsheets/d/${formHistoryId}`
                )
              }
              return JSON.parse(response.body)
            })

          return newFormValues
        }
      }
    } catch (err) {
      return Promise.reject(err)
    }
  },
}
