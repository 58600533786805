import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  margin-bottom: 80px;
`
export const Note = styled.div`
  width: 100%;
`

export const Chip = styled.span`
  border: 1px solid ${({ theme }) => theme.borderColor};
  padding: 2px 10px;
  margin-right: 10px;
  border-radius: 20px;
`
