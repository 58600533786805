/// <reference types="gapi"/>
import React, { useState, useEffect } from "react"
import { Switch, Route, useHistory } from "react-router-dom"
import {
  initGapi,
  loadAppScriptLibrary,
  loadCalendarLibrary,
  loadClienAuthLibrary,
  loadScript,
} from "../../services/google"

import {
  AppContainer,
  AppContent,
  SideNavWhiteSpace,
  BottomWhiteSpace,
  LeftContainer,
  RightContainer,
} from "./styles.css"
import { themes } from "../../theme"
import { ThemeProvider } from "styled-components"

import { connect, ConnectedProps } from "react-redux"
import { fetchUser } from "../../features/userProfile/userProfileSlice"
import { fetchCurriculum } from "../../features/curriculum/curriculumSlice"

import { SnackbarProvider } from "../../hooks/useSnackbar/index"

import Settings from "../../features/settings"
import Cohorts from "../../features/cohorts"
import TopNav from "../topNav"
import SideNav from "../sideNav"

import Error from "../error"
import {
  ReduxMsg,
  ReduxStatus,
  User,
  LearningStandard,
  WhiteboardPrompt,
} from "../../../global"
import { fetchWhiteboardPrompts } from "../../features/prompts/whiteboardPromptsSlice"

const mapState =
  () =>
  ({
    whiteboard_prompts: whiteboardPrompts,
    user_profile: userProfile,
    curriculum,
  }: {
    user_profile: { status: ReduxStatus; msg: ReduxMsg; data: User }
    curriculum: { status: ReduxStatus; msg: ReduxMsg; data: LearningStandard[] }
    whiteboard_prompts: {
      status: ReduxStatus
      msg: ReduxMsg
      data: WhiteboardPrompt[]
    }
  }) => ({
    userProfile,
    curriculum,
    whiteboardPrompts,
  })
const mapDispatch = {
  fetchUser,
  fetchCurriculum,
  fetchWhiteboardPrompts,
}
const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux

const App = ({
  fetchUser,
  fetchCurriculum,
  fetchWhiteboardPrompts,
  whiteboardPrompts: {
    status: whiteboardPrompts_status,
    data: whiteboardPrompts,
  },
  curriculum: { status: curriculum_status, data: curriculum },
  userProfile: { status: userProfile_status, data: userProfile },
}: Props) => {
  const history = useHistory()
  const [isGapiLoaded, setGapiLoaded] = useState(false)
  const [theme, toggleTheme] = useState<"light" | "dark">(
    localStorage
      ? (localStorage.getItem("mbear_theme") as "light" | "dark")
      : "light"
  )
  const handleToggleTheme = () => {
    theme === "light"
      ? localStorage.setItem("mbear_theme", "dark")
      : localStorage.setItem("mbear_theme", "light")
    theme === "light" ? toggleTheme("dark") : toggleTheme("light")
  }

  useEffect(() => {
    const loadGapi = async () => {
      const GAPI_SRC = "https://apis.google.com/js/platform.js?onload=init"
      try {
        await loadScript(GAPI_SRC)
        await loadClienAuthLibrary()
        await loadAppScriptLibrary()
        await loadCalendarLibrary()
        const user = await initGapi()
        if (!user) throw TypeError("user does not exist")
        await fetchUser(user)
        await fetchCurriculum()
        await fetchWhiteboardPrompts()
        setGapiLoaded(true)
      } catch (e) {
        history.push("/login")
        console.error(`GAPI NOT LOADED: ${e}`)
      }
    }

    if (!isGapiLoaded || !userProfile) loadGapi()
  }, [])

  return (
    <ThemeProvider theme={theme === "light" ? themes.light : themes.dark}>
      {isGapiLoaded && (
        <SnackbarProvider>
          <AppContainer>
            <LeftContainer>
              <SideNav theme={theme} />
              <SideNavWhiteSpace />
            </LeftContainer>
            <RightContainer>
              <TopNav toggleTheme={handleToggleTheme} theme={theme} />
              <AppContent>
                <Switch>
                  <Route path="/overview">
                    <Cohorts />
                  </Route>
                  <Route path="/cohorts">
                    <Cohorts />
                  </Route>
                  <Route path="/settings">
                    <Settings />
                  </Route>
                  <Route path="/error">
                    <Error />
                  </Route>
                </Switch>
              </AppContent>
              <BottomWhiteSpace />
            </RightContainer>
          </AppContainer>
        </SnackbarProvider>
      )}
    </ThemeProvider>
  )
}

export default connector(App)
