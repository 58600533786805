import React, { useState } from "react"
import {
  Container,
  ListHeader,
  ListItemContainer,
  HeaderDetail,
  FilterContainer,
} from "./styles.css"
import ListItem from "../listItem"

type FixMe = any

interface Props {
  headers: FixMe[] //these are ordered column headers (can be jsx element or string)
  headerFilters: JSX.Element[] // components that handle filtering column
  textCenterMap?: boolean[] //should text for row item be centered
  showFilters?: boolean // show filterable headers at all. default is false
  visibleContent: string[] //these are properties of listData that should be visible on list
  columnWidths: string[] // pixel min-width for columns
  listData: FixMe[] //this is a generic array of key/value objects. the full dataset
  listItemUrlHandler?: (urlPath: string) => void //this is the handler for routing
  listItemUrlVar: string //this is the url variable name for routing
  listItemAction?: boolean[] //array of booleans weather click will trigger list item action or just list item
}

const ListView = ({
  headers,
  headerFilters,
  listData,
  textCenterMap = headers.map(() => false),
  showFilters = false,
  columnWidths,
  visibleContent,
  listItemUrlHandler,
  listItemUrlVar,
  listItemAction,
}: Props) => {
  const [filterView, setFilterView] = useState<boolean[]>(
    [...headers].map(() => false)
  )
  const Filters = headers.map((title: string, idx: number) => {
    return <div key={`${title}-filter-${idx}`}>{headerFilters[idx]}</div>
  })
  return (
    <Container>
      <ListHeader>
        <tr>
          {headers.map((title: string, idx: number) => (
            <HeaderDetail
              style={{
                width: columnWidths[idx],
                justifyContent: textCenterMap[idx] ? "center" : "left",
              }}
              key={`header-${title}-${idx}`}
              onMouseEnter={() =>
                setFilterView(
                  filterView.map((boolStatus, idy) =>
                    idx === idy ? !boolStatus : false
                  )
                )
              }
            >
              {showFilters && (
                <FilterContainer>
                  {filterView[idx] && headerFilters[idx] ? Filters[idx] : "- "}
                </FilterContainer>
              )}
              {title}
            </HeaderDetail>
          ))}
        </tr>
      </ListHeader>
      <ListItemContainer>
        {listData.map((itemData: any, idx: number) => {
          const itemDetails = visibleContent.reduce(
            (acc: FixMe, property: string) => {
              acc[property] = itemData[property]
              return acc
            },
            {}
          )
          return (
            <ListItem
              columnWidths={columnWidths}
              itemDetails={itemDetails}
              itemData={itemData}
              itemUrlVar={listItemUrlVar}
              itemTextCenter={textCenterMap}
              itemUrlHandler={listItemUrlHandler}
              itemAction={listItemAction ? listItemAction[idx] : false}
              key={`${itemData}-${idx}`}
            />
          )
        })}
      </ListItemContainer>
    </Container>
  )
}

export default ListView
