import styled from "styled-components"
import { H1 } from "../base/styles.css"
import Switch from "@material-ui/core/Switch"

export const Container = styled.div`
  height: 45px;
  width: calc(100vw - 170px);
  background: ${({ theme }) => theme.backgroundColor.primary};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 5;
  flex-direction: row-reverse;
`

export const Title = styled(H1)`
  margin: 0 10px;
  min-width: 200px;
  font-weight: normal;
`
export const NavIconContainer = styled.div`
  margin: 0 10px;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`

export const ThemeSwitch = styled(Switch)`
  background-color: ${({ theme }) => theme.backgroundColor.navbar}!important;
  border: none;

  outline: none;
  &:focus {
    outline: none;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: ${({ theme }) => theme.color.text.normal};
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.color.text.normal};
  }
  > span.MuiSwitch-track {
    border: 1px solid ${({ theme }) => theme.color.text.normal};
  }
`
